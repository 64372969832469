import { useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Data, Title } from "../../components";
import Loading from "../../Tools/Loading";

function PaymentDetails() {
  const { id, idd } = useParams();
  const { data, isLoading } = useFETCH(
    `admin/transactions/${id}/payments/${idd}`
  );
  let dataAll = data?.data.data;
  console.log(dataAll);
  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Title title="الدفعة" />
        <Col className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl">
          <Row>
            <Col md={7}>
              <Data name="قيمة الدفعة الإجمالية" text={dataAll?.amount} />
            </Col>
            {sessionStorage.getItem("role_name") === "register_manager" ||
            sessionStorage.getItem("role_name") ===
              "financial_collection_officer" ? (
              ""
            ) : (
              <Col md={7}>
                <Data
                  name="حالة الدفعة"
                  text={dataAll?.state === 2 ? "مقبولة" : "مرفوضة"}
                />
              </Col>
            )}
            <Col md={7}>
              <Data name="تاريخ الدفعة" text={dataAll?.date} />
            </Col>
            <Col md={7}>
              <Data name="طريقة الدفع" text={dataAll?.payment_method?.name} />
            </Col>
            <Col md={7}>
              <Data name="ملاحظة" text={dataAll?.notes} />
            </Col>
            <Col md={7}>
              <Data name="الدفعة المستلمة" text={dataAll?.received_amount} />
            </Col>
            <Col md={7}>
              <Data name="الدفعة المُحولة" text={dataAll?.transfer_amount} />
            </Col>
            <Col
              md={7}
              className={`${
                sessionStorage.getItem("role_name") ===
                  "financial_collection_officer" ||
                sessionStorage.getItem("role_name") === "register_manager"
                  ? "hidden"
                  : ""
              }`}
            >
              <Data
                name="تاريخ وصول الحوالة"
                text={dataAll?.transfer_arrival_date}
              />
            </Col>
            <Col md={7}>
              <Data name="تاريخ الحوالة" text={dataAll?.transfer_date} />
            </Col>
            <Col md={7}>
              <Data name="رقم الحوالة" text={dataAll?.transfer_number} />
            </Col>
            <Col md={7}>
              <Data
                name="اسم مستلم الحوالة"
                text={dataAll?.transfer_recipient_name}
              />
            </Col>
            <Col md={7}>
              <Data
                name="اسم الذي قام بالحوالة"
                text={dataAll?.transferred_by}
              />
            </Col>
            <Col
              md={7}
              className={`${
                sessionStorage.getItem("role_name") ===
                  "financial_collection_officer" ||
                sessionStorage.getItem("role_name") === "register_manager"
                  ? "hidden"
                  : ""
              }`}
            >
              <Data name="المبلغ الذي وصل" text={dataAll?.received_amount} />
            </Col>
            <Col md={7}>
              <ButtonRed
                link={`/transactions/${id}/payment/${idd}`}
                name="تعديل الدفعة"
                className="!w-fit !px-3"
              />
            </Col>
            {sessionStorage.getItem("role_name") === "financial_manager" ||
              (sessionStorage.getItem("role_name") === "super_admin" && (
                <Col md={7}>
                  <Data
                    name="عمولة مستلم الحوالة"
                    text={dataAll?.transfer_recipient_commission}
                  />
                </Col>
              ))}

            <Col md={7}></Col>
            <Col xs={6} lg={4}>
              <img
                onClick={clickZoomInImage}
                src={fileUrl + dataAll?.transfer_image}
                alt=""
                className="rounded-3xl mt-5 w-full"
              />
            </Col>
          </Row>
        </Col>
      </Container>
      <Back />
    </div>
  );
}
export default PaymentDetails;
