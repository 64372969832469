import DeleteParamsAll from "./DeleteParamsAll";
import SingleButFiler from "./SingleButFiler";
function WalletFilter({ showDel }) {
  return (
    <>
      <DeleteParamsAll />
      <SingleButFiler value="1" name="completed" title="المقبوضة" />
      <SingleButFiler value="0" name="completed" title=" الغير مقبوضة" />

      {sessionStorage.getItem("role_name") === "financial_manager" ||
        (sessionStorage.getItem("role_name") === "super_admin" && !showDel && (
          <SingleButFiler value="1" name="trashed" title="  سجلات المحذوفين " />
        ))}
    </>
  );
}
export default WalletFilter;
