import { useFilter } from "../../Tools/APIs";
import { Col } from "../../Tools/Grid-system";

const AdminsFilter = () => {
  const { handleParamsClick, searchParams, handleParamsDelete } = useFilter();
  return (
    <>
      <Col lg={3} md={4} xs={5}>
        <select
          value={searchParams.get("role")}
          onChange={(e) => handleParamsClick("role", e.target.value)}
          className="w-full bg-Third rounded-xl py-2 text-center px-5 text-white outline-none"
        >
          <option value="" onClick={() => handleParamsDelete("role")}>
            الكل
          </option>
          <option value="financial_manager">المدراء الماليين</option>
          <option value="register_manager">مدراء التسجيل</option>
          <option value="financial_collection_officer">
            موظفو التحصيل المالي
          </option>
          <option value="other">بقية الكادر</option>
        </select>
      </Col>
    </>
  );
};

export default AdminsFilter;
