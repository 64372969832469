import Delete from "../Delete/Delete";
import { Link } from "react-router-dom";
import Pagination from "../../Tools/Pagination";
import { AiFillPrinter } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import Download from "../Delete/Download";
import UpLoad from "../Delete/UpLoad";
import { useClose, useFilter } from "../../Tools/APIs";
import Recovery from "../Delete/Recovery";
import EditSittingNumber from "../Delete/EditSittingNumber";
import transactions from "../../svg/sort_12963813.svg";
import { Tooltip } from "react-tooltip";
// import students from "../../svg/leadership_8914016 (1).svg";
import students from "../../svg/graduated_3135810.svg";
import { MdOutlineMarkEmailUnread, MdOutlinePayments } from "react-icons/md";
import Update from "../Delete/Update";

const Table = ({
  thead,
  tbody,
  tData,
  shows,
  show,
  show2,
  funDelete,
  pageCount,
  edit,
  name,
  print,
  showTransactions,
  showPro,
  showProStudent,
  showDe,
  onClickSendToEmail,
  summary,
}) => {
  const { searchParams } = useFilter();
  return (
    <div>
      <Pagination pageCount={pageCount}>
        <div className="mx-auto md:w-[95%] py-4 overflow-x-auto h-full pb-20 ">
          <table className=" text-center w-full mx-auto border overflow-hidden rounded-2xl h-full  bg-white">
            <thead className="bg-Main text-white w-full pb-52">
              <tr>
                <td className="p-5 w-fit">رقم</td>
                {thead?.map((e, i) => (
                  <td key={i} className="p-5">
                    {e}
                  </td>
                ))}
                <td className=" !w-[150px]">العمليات</td>
              </tr>
            </thead>
            <tbody>
              {tData &&
                tData?.map((e, i) => (
                  <tr key={i} className={e.is_completed ? "bg-green-300" : ""}>
                    <td className="border-Main border p-2">{e.id}</td>
                    {tbody?.map((eName, i) => (
                      <td key={i} className="border-Main border p-2">
                        {Array.isArray(e[name])
                          ? e[name].map((ele) => <div>{ele.name}</div>)
                          : eName.name === "is_completed"
                          ? e.is_completed
                            ? "مكتملة"
                            : "غير مكتملة"
                          : eName.object
                          ? e[eName.name]?.[eName.object]
                          : eName.name
                          ? e[eName.name]
                          : "___"}
                      </td>
                    ))}
                    <td className="relative border-Main border w-fit">
                      <div className="flex justify-end items-center space-x-1">
                        {onClickSendToEmail &&
                        !e.is_completed &&
                        sessionStorage.getItem("role_name") !==
                          "financial_collection_officer" ? (
                          <SendEmailToS
                            onClickSendToEmail={onClickSendToEmail}
                            id={e.id}
                          />
                        ) : (
                          <div className="w-5 h-5"></div>
                        )}
                        {summary &&
                          sessionStorage.getItem("role_name") !==
                            "financial_collection_officer" && (
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <div id="my-anchor-summary">
                                <Link
                                  to={`/transactions/${e.id}/payments/summary/pdf`}
                                >
                                  <AiFillPrinter
                                    size={25}
                                    className="text-gray-600"
                                  />
                                  <Tooltip
                                    anchorSelect="#my-anchor-summary"
                                    content="طباعة كشف الحساب"
                                  />
                                </Link>
                              </div>
                            </div>
                          )}

                        {e.sitting_number === null &&
                        searchParams.get("trashed") !== "1" ? (
                          <div id="my-anchor-element_email">
                            <div className="w-fit">
                              <EditSittingNumber
                                id={e.id}
                                email={e.email}
                                name={e.name}
                                phone_number={e.phone_number}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {print && (
                          <>
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <div id="my-anchor-element">
                                <AiFillPrinter
                                  size={25}
                                  className="text-orange-400"
                                />
                                <Tooltip
                                  anchorSelect="#my-anchor-element"
                                  content="طباعة الفاتورة"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        {showProStudent && (
                          <Link to={`/students/transactions/${e.id}`}>
                            <div
                              className={`w-10 h-10  rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <div id="my-anchor-element">
                                <img
                                  src={transactions}
                                  alt=""
                                  className="w-7 h-7"
                                />
                                <Tooltip
                                  anchorSelect="#my-anchor-element"
                                  content="عرض  الملف المالي"
                                />
                              </div>
                            </div>
                          </Link>
                        )}
                        {showPro && (
                          <div id="my-anchor-element_8">
                            <Link
                              to={`/items/${e.id}/processes?category_id=${e.id}`}
                            >
                              <FaEye size={27} className="text-gray-700" />
                            </Link>
                            <Tooltip
                              anchorSelect="#my-anchor-element_8"
                              content="عرض المصاريف"
                            />
                          </div>
                        )}
                        {showTransactions && (
                          <>
                            <div id="my-anchor-element-2">
                              <Link to={`/transactions/${e.id}/payment`}>
                                <MdOutlinePayments
                                  size={30}
                                  className="text-green-500"
                                />
                              </Link>
                              <Tooltip
                                anchorSelect="#my-anchor-element-2"
                                content="الدفعات"
                              />
                            </div>
                            <div id="my-anchor-element-1" className="w-6 h-6">
                              <Link to={`/students/info/${e.student?.id}`}>
                                <img
                                  src={students}
                                  alt=""
                                  className={` bg-  rounded-full `}
                                />
                              </Link>
                              <Tooltip
                                anchorSelect="#my-anchor-element-1"
                                content="عرض معلومات الطالب "
                              />
                            </div>
                          </>
                        )}
                        {searchParams.get("trashed") === "1" && (
                          <Recovery id={e.id} name={e.name} />
                        )}
                        {show && (
                          <div id="my-anchor-element-show2">
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <Link to={`/${show}/${e.id}/${show2}`}>
                                <FaEye size={27} className="text-blue-700" />
                              </Link>
                            </div>
                            <Tooltip
                              anchorSelect="#my-anchor-element-show2"
                              content="عرض التخصصات"
                            />
                          </div>
                        )}
                        {showDe && (
                          <div
                            id={
                              e.degrees.length > 0 &&
                              "my-anchor-element-show" + e.id
                            }
                          >
                            {e.degrees.length > 0 ? (
                              <div
                                className={`w-10 h-10 rounded-full flex justify-center items-center cursor-default hover:bg-slate-200`}
                              >
                                <FaEye size={27} className="text-blue-700" />
                              </div>
                            ) : (
                              <div className={`w-10 h-10 `}></div>
                            )}

                            <Tooltip
                              anchorSelect={"#my-anchor-element-show" + e.id}
                              content={
                                <>
                                  <div className="flex justify-between">
                                    <div>الاسم</div>
                                    <div>-</div>
                                    <div>السعر</div>
                                  </div>
                                  {e.degrees.map((e) => (
                                    <div className="flex justify-between">
                                      <div>{e.name}</div>
                                      <div>-</div>
                                      <div>{e.price}</div>
                                    </div>
                                  ))}
                                </>
                              }
                            />
                          </div>
                        )}
                        {edit && (
                          <Update name={e.name} edit={`/${edit}/${e.id}`} />
                        )}
                        {shows && (
                          <div id="my-anchor-element-10">
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <Link to={`/${shows}/${e.id}`}>
                                <FaEye size={27} className="text-blue-700" />
                              </Link>
                            </div>
                            <Tooltip
                              anchorSelect="#my-anchor-element-10"
                              content="عرض التفاصيل"
                            />
                          </div>
                        )}
                        {funDelete &&
                        searchParams.get("trashed") !== "1" &&
                        e?.is_fixed !== 1 ? (
                          <Delete
                            id={e.id}
                            name={e.display || e.name}
                            onClick={() => funDelete(e)}
                          />
                        ) : (
                          <div className="w-10 "></div>
                        )}

                        {e.in_archive === 1 ? (
                          <UpLoad
                            showTransactions={showTransactions}
                            name={name}
                            iddd={`${e.id}`}
                          />
                        ) : (
                          ""
                        )}
                        {e.in_archive === 0 ? (
                          <Download
                            showTransactions={showTransactions}
                            name={name}
                            iddd={`${e.id}`}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </Pagination>
    </div>
  );
};

export default Table;
const SendEmailToS = ({ onClickSendToEmail, id }) => {
  const { mouse, open, setOpen } = useClose();
  return (
    <>
      <div ref={mouse} className="w-fit relative">
        {open && (
          <>
            <div
              className={`absolute bg-white rounded-xl border border-Secondary -top-10 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center`}
            >
              <div className="flex items-center justify-center m-5">
                <div className="flex gap-2 mx-3">
                  <div
                    onClick={() =>
                      onClickSendToEmail(
                        `admin/transactions/${id}/checkout/reminder`,
                        "",
                        true
                      )
                    }
                    className="px-3 py-2 bg-Third my-2 rounded-lg text-white cursor-pointer hover:bg-opacity-75"
                  >
                    ارسال
                  </div>
                </div>

                <button
                  onClick={() => setOpen(false)}
                  className="px-3 py-2 bg-Secondary my-2 rounded-lg text-white cursor-pointer hover:bg-opacity-75"
                >
                  تراجع
                </button>
              </div>
            </div>
          </>
        )}
        <div id="my-anchor-element_email">
          <div className="w-fit">
            <MdOutlineMarkEmailUnread
              onClick={() => setOpen(true)}
              size={25}
              className="text-red-400"
            />
          </div>
          <Tooltip
            anchorSelect="#my-anchor-element_email"
            content="ارسال تذكير للطالب"
          />
        </div>
      </div>
    </>
  );
};
// public function store(SpecialtySection $specialtySection, CreateSpecialtyRequest $request): JsonResponse
//     {
//         return DB::transaction(function () use ($request, $specialtySection) {
//             $specialty = $specialtySection->specialties()->create($request->only('name', 'price'));
//             foreach ($request->degrees as $degree) {
//                 $specialty->degrees()->syncWithoutDetaching([$degree['id']=>['price' => $degree['price']]]);
//             }
//             return success($specialty);
//         });

//     }
// export const Menus = ({ children }) => {
//   const { mouse, open, setOpen } = useClose();
//   return (
//     <div ref={mouse} className=" transition-all relative">
//       <CiMenuKebab onClick={() => setOpen(!open)} />
//       {open && (
//         <div className=" max-h-[] absolute z-50 bottom-0 text-sm rounded-xl -right-28 mr-4 min-w-[120px] bg-slate-300   ">
//           {children}
//         </div>
//       )}
//     </div>
//   );
// };
