import React from "react";
import { Col } from "../../Tools/Grid-system";
import { useLocation } from "react-router-dom";
import { useFilter } from "../../Tools/APIs";

const DeleteParamsAll = () => {
  const { handleParamsDeleteAll } = useFilter();
  const { search } = useLocation();
  return (
    <Col lg={3} md={4} xs={5}>
      <div
        className={`border-2 border-Third  rounded-xl hover:bg-opacity-70 text-center cursor-pointer w-full px-5 py-[0.6rem]
       ${search ? " bg-white text-Third " : "bg-Third text-white "} `}
        onClick={() => handleParamsDeleteAll()}
      >
        الكل
      </div>
    </Col>
  );
};

export default DeleteParamsAll;
