import { HiArrowLeft } from "react-icons/hi";
import { Tooltip } from "react-tooltip";

const Back = () => {
  return (
    <div className="fixed top-28 left-5">
      <div id="my-anchor-element-back">
        <HiArrowLeft
          className="cursor-pointer"
          size={30}
          onClick={() => window.history.go(-1)}
        />
        <Tooltip anchorSelect="#my-anchor-element-back" content="رجوع" />
      </div>
    </div>
  );
};

export default Back;
