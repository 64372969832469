import Input from "../../components/Input/Input";
import { useEffect, useState } from "react";
import { ButtonRed } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
function SetPassword() {
  const [password, setPassword] = useState(false);
  const {
    setFormData,
    handleSubmit,
    error,
    loading,
    handleChangeInput,
    formData,
  } = usePOST({});
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit("admin/reset-password", "/login");
  };

  useEffect(() => {
    setFormData({
      ...formData,
      verification_code: sessionStorage.getItem("code"),
      email: sessionStorage.getItem("email"),
    });
  }, []);
  return (
    <Container>
      <Row className="h-[70vh] flex justify-center items-center">
        <Col
          md={8}
          className="mx-auto space-y-3 bg-white p-8 rounded-3xl border-t shadow-xl "
        >
          <h1 className="text-2xl font-semibold text-center">كلمة السر</h1>
          <Input
            name="password"
            type={!password ? "password" : "text"}
            title="أدخل كلمة المرور"
            onChange={handleChangeInput}
          />
          <Input
            name="password_confirmation"
            type={!password ? "password" : "text"}
            title="تأكيد كلمة المرور"
            onChange={handleChangeInput}
          />
          <div className=" mt-5 flex items-center">
            <input
              type="checkbox"
              onClick={() => setPassword(!password)}
              name="checkbox"
              className="mx-3 w-fit"
            />
            <span>إظهار كلمة المرور</span>
          </div>
          {loading ? <Loading /> : ""}

          <ButtonRed
            className="w-[150px] mx-auto mt-2"
            name="تأكيد كلمة المرور"
            onClick={handleSubmitMain}
          />
        </Col>
      </Row>
    </Container>
  );
}
export default SetPassword;
