import { Back, DateFilter, Search, WalletFilter } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { useFETCH } from "../../Tools/APIs";
import { IoIosTimer } from "react-icons/io";
import { useLocation, useParams } from "react-router-dom";

function MyInfo() {
  const { id } = useParams();
  const { search } = useLocation();
  const { data, isLoading } = useFETCH(
    `admin/balance/logs${search}`,
    `admin/balance/logs`
  );
  return (
    <>
      <Container>
        <Row>
          <Col xs={7} md={4} lg={3}>
            <Search />
          </Col>
          <Col xs={7} md={4} lg={3}>
            <DateFilter />
          </Col>
        </Row>
        <Row>
          <WalletFilter showDel={true} />
        </Row>
        <Row className="justify-center">
          <Col>
            <div
              className={`flex items-center gap-1 w-[300px] mx-auto font-bold text-Secondary mt-3 `}
            >
              <p className="">الرصيد الحالي :</p>
              <p className="font-normal text-Third flex-1">
                {data?.data?.total_balance}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row justify="center md:px-3">
          <Col className="overflow-x-auto">
            <table className="w-full  max-lg:w-[850px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-4 border-Secondary">
              <thead className="bg-Main text-white h-[70px]">
                <tr>
                  <td className="px-7">رقم</td>
                  <td className="px-7">المبلغ</td>
                  <td className="px-7">الرصيد الجديد</td>
                  <td className="px-7">النوع</td>
                  <td className="px-7">تاريخ الإنشاء</td>
                  <td className="px-7">حالة السجل</td>
                  <td className="px-7">السبب</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e, index) => (
                    <tr
                      key={index}
                      className={`border border-Main   ${
                        index === 0 && id !== "all"
                          ? "bg-Third text-white"
                          : "bg-white"
                      }`}
                    >
                      <td className="">{e.id}</td>
                      <td
                        className={
                          e.amount_type === "CHARGE"
                            ? "text-green-500"
                            : "text-red-600"
                        }
                      >
                        {e.amount_type === "CHARGE" ? "+" : "-"} {e.amount}
                      </td>
                      <td className="">{e.balance_after}</td>
                      <td
                        className={`${
                          e.amount_type === "CHARGE"
                            ? "text-green-500"
                            : "text-red-600"
                        }  font-bold `}
                      >
                        {e.amount_type === "CHARGE" ? "إضافة" : "خصم"}
                      </td>
                      <td className="">{e.created_at.substring(0, 10)}</td>
                      <td className="flex items-center justify-center gap-2 h-10 ">
                        <p>
                          {e.status === "PENDING" && <IoIosTimer size={20} />}
                        </p>
                        <div>{e.status_display}</div>
                      </td>
                      <td className="">{e.reason}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      <Back />
    </>
  );
}
export default MyInfo;
