import { useParams } from "react-router-dom";
import { clickZoomInImage, fileUrl, useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Back, Data, Title } from "../../components";
import Loading from "../../Tools/Loading";

function SingleStudents() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/students/${id}`);
  let dataAll = data?.data.data;
  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Title title="الطالب" />
        <Col
          lg={14}
          className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl"
        >
          <Row className="justify-center">
            <Col md={7}>
              <Data name="الاسم" text={dataAll?.name} />
            </Col>
            <Col md={7}>
              <Data name="رقم الهاتف" text={dataAll?.phone_number} />
            </Col>
            <Col md={7}>
              <Data name="الإيميل" text={dataAll?.email} />
            </Col>
            <Col md={7}>
              <Data name="الحالة" text={dataAll?.status} />
            </Col>
            <Col md={7}>
              <Data name="الدرجة العلمية" text={dataAll?.degree} />
            </Col>
            <Col md={7}>
              <Data name="رقم الجلوس" text={dataAll?.sitting_number} />
            </Col>
            {dataAll?.certificates.length > 0 ? (
              <>
                <h2 className="font-bold text-Main mt-5 mr-20 max-lg:mr-[10%]">
                  الشهادات
                </h2>
                {dataAll?.certificates.map((e, i) => (
                  <Col key={i} xs={6} lg={4}>
                    <img
                      onClick={clickZoomInImage}
                      src={fileUrl + e.file}
                      alt=""
                      className="rounded-3xl mt-5 w-full"
                    />
                  </Col>
                ))}
              </>
            ) : (
              <div className="flex items-center gap-3 mr-20 max-lg:mr-[10%]">
                <h2 className="font-bold text-Main">الشهادات</h2>
                <span>لا يوجد صور شهادات</span>
              </div>
            )}
            {dataAll?.official_documents.length > 0 ? (
              <>
                <h2 className="font-bold text-Main mt-5 mr-20 max-lg:mr-[10%]">
                  مستندات رسمية
                </h2>
                {dataAll?.official_documents.map((e ,i) => (
                  <Col key={i} xs={6} lg={4}>
                    <img
                      onClick={clickZoomInImage}
                      src={fileUrl + e.file}
                      alt=""
                      className=" rounded-3xl mt-5 w-full"
                    />
                  </Col>
                ))}
              </>
            ) : (
              <div className="flex items-center gap-3 mr-20 max-lg:mr-[10%]">
                <h2 className="font-bold text-Main">المستندات الرسمية</h2>
                <span>لا يوجد صور مستندات رسمية</span>
              </div>
            )}
          </Row>
        </Col>
      </Container>
      <Back />
    </div>
  );
}
export default SingleStudents;
