function Data({ name, text, className }) {
  return (
    <div
      className={`flex items-center gap-1 justify-center font-bold text-Main mt-3 ${className}`}
    >
      <p className="">{name} :</p>
      <p className="font-normal text-black flex-1">{text || "لا يوجد "}</p>
    </div>
  );
}
export default Data;
