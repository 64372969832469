import { useParams } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Back, Data, Title } from "../../components";
import Loading from "../../Tools/Loading";

function SingleUser() {
  const { id } = useParams();
  const { data, isLoading } = useFETCH(`admin/users/${id}?role=beneficiary`);
  let dataAll = data?.data.data;
  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Title title="عضو الإدارة" />
        <Col
          lg={8}
          className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl"
        >
          <Row>
            <Col md={6}>
              <Data name="الاسم" text={dataAll?.name} />
            </Col>
            <Col md={6}>
              <Data name="الرصيد" text={dataAll?.balance} />
            </Col>
            <Col md={6}>
              <Data name="دور المدير" text={dataAll?.role?.display} />
            </Col>
          </Row>
        </Col>
      </Container>
      <Back />
    </div>
  );
}
export default SingleUser;
