import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, ButtonRed, Input, PasswordInput, Title } from "../../components";

const AddUser = () => {
  const { id } = useParams();
  const { setMessage } = useContextHook();
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({});
  const { data: dataRoles } = useFETCH(`admin/roles`);
  const { data, isLoading } = useFETCH(id === "add" ? "" : `admin/users/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    if (formData?.passwordConfirmation === formData?.password) {
      handleSubmit(id === "add" ? "admin/users" : `admin/users/${id}`);
    } else {
      setMessage(["كلمة المرور وتأكيد كلمة المرور غير متطابقتين"]);
    }
  };
  const selectRef = useRef(null);
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        username: dataOld?.username,
        phone_number: dataOld?.phone_number,
        email: dataOld?.email,
        [dataOld?.salary && "salary"]: dataOld?.salary,
        [dataOld?.commission >= 0 && "commission"]: dataOld?.commission,
        [dataOld?.fc_commission >= 0 && "fc_commission"]:
          dataOld?.fc_commission,
        [dataOld?.fc_commission_type && "fc_commission_type"]:
          dataOld?.fc_commission_type,
        [dataOld?.name && "name"]: dataOld?.name,
        [dataOld?.commission_type && "commission_type"]:
          dataOld?.commission_type,
        role_id: dataOld?.role_id,
      });
    if (id !== "add" && dataOld?.commission > 0) {
      setIsCheckboxChecked(true);
    }
    if (id !== "add" && dataOld?.fc_commission > 0) {
      setIsCheckboxCheckedTow(true);
    }
  }, [dataOld]);
  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.value = formData.role_id || dataOld?.role_id;
      id === "add" &&
        selectRef.current.dispatchEvent(new Event("change", { bubbles: true }));
    }
  }, [formData.role_id, dataOld]);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isCheckboxCheckedTow, setIsCheckboxCheckedTow] = useState(false);
  const handleChangeInputNumber = (event) => {
    const { name, value } = event.target;
    if (value >= 0 && value <= 100) {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  return (
    <div className="">
      <Title
        title={id === "add" ? "أضف إلى الكادر" : "تعديل الكادر"}
        className="mt-5"
      />
      {isLoading && id !== "add" && <Loading />}
      {(!isLoading || id === "add") && (
        <Row>
          <Col
            lg={9}
            sm={14}
            className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto"
          >
            <select
              text="نوع الحساب"
              title="اختر نوع الحساب"
              name="role_id"
              ref={selectRef}
              value={formData?.role_id}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  role_id: e.target.value,
                });
                setIsCheckboxChecked(false);
                setIsCheckboxCheckedTow(false);
                if (
                  formData?.commission ||
                  formData?.commission_type ||
                  formData?.fc_commission ||
                  formData?.fc_commission_type
                ) {
                  setFormData((prevFormData) => {
                    const {
                      commission,
                      commission_type,
                      fc_commission,
                      fc_commission_type,
                      ...formData
                    } = prevFormData;
                    return formData;
                  });
                }
              }}
              className={`w-full border-2 border-Secondary py-3 rounded-xl  outline-none text-center mx-auto `}
            >
              <option value="" disabled hidden selected>
                {dataRoles ? " اختر نوع الحساب" : "الرجاء الانتظار"}
              </option>
              {dataRoles?.data.data.map((e) => (
                <option key={e.id} data-mode={e.name} value={e.id}>
                  {e.display}
                </option>
              ))}
            </select>
            {selectRef.current?.options[selectRef.current.selectedIndex]
              ?.dataset.mode && (
              <>
                {selectRef.current?.options[selectRef.current.selectedIndex]
                  ?.dataset.mode === "register_manager" && (
                  <>
                    <div className="flex items-center gap-3 my-4  font-semibold">
                      <div
                        onClick={() => {
                          setIsCheckboxChecked(!isCheckboxChecked);
                          if (isCheckboxChecked) {
                            setFormData((prevFormData) => {
                              const {
                                commission,
                                commission_type,
                                ...formData
                              } = prevFormData;
                              return formData;
                            });
                          }
                        }}
                        className={`w-6 h-6 border border-black rounded-md ${
                          isCheckboxChecked ? "bg-Main" : ""
                        }`}
                      />
                      <p>إضافة عمولة التسجيل</p>
                    </div>
                    {isCheckboxChecked && (
                      <>
                        <h1 className="text-center text-xl font-bold">
                          عمولة التسجيل
                        </h1>
                        <div className="flex justify-center items-center gap-10 mb-5 mt-1">
                          <div className="flex justify-center items-center gap-2">
                            <div className="bg-Main w-fit rounded-full p-[1.2px]">
                              <div
                                className={`${
                                  formData?.commission_type === "MONETARY"
                                    ? "bg-Main"
                                    : "bg-white"
                                }  w-[18px] h-[18px] rounded-full border-[3px] border-white`}
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    commission_type: "MONETARY",
                                  });
                                }}
                              />
                            </div>
                            <p className="text-xl font-bold">مبلغ مقطوع</p>
                          </div>
                          <div className="flex justify-center items-center gap-2">
                            <div className="bg-Main w-fit rounded-full p-[1.2px]">
                              <div
                                className={`${
                                  formData?.commission_type === "PERCENTAGE"
                                    ? "bg-Main"
                                    : "bg-white"
                                } w-[18px] h-[18px] rounded-full border-[3px] border-white`}
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    commission_type: "PERCENTAGE",
                                  });
                                }}
                              />
                            </div>
                            <p className="text-xl font-bold">
                              نسبة من الملف المالي 
                            </p>
                          </div>
                        </div>
                        {formData?.commission_type === "MONETARY" && (
                          <>
                            <Input
                              type="number"
                              name="commission"
                              value={formData?.commission}
                              onChange={handleChangeInput}
                              title="مبلغ مقطوع"
                            />
                          </>
                        )}
                        {formData?.commission_type === "PERCENTAGE" && (
                          <Input
                            type="number"
                            name="commission"
                            value={formData?.commission}
                            onChange={handleChangeInputNumber}
                            title="نسبة من الملف المالي "
                          />
                        )}
                      </>
                    )}
                  </>
                )}
                {(selectRef.current?.options[selectRef.current.selectedIndex]
                  ?.dataset.mode === "financial_collection_officer" ||
                  selectRef.current?.options[selectRef.current.selectedIndex]
                    ?.dataset.mode === "register_manager") && (
                  <>
                    <div className="flex items-center gap-3 my-4  font-semibold">
                      <div
                        onClick={() => {
                          setIsCheckboxCheckedTow(!isCheckboxCheckedTow);
                          if (isCheckboxCheckedTow) {
                            setFormData((prevFormData) => {
                              const {
                                fc_commission_type,
                                fc_commission,
                                ...formData
                              } = prevFormData;
                              return formData;
                            });
                          }
                        }}
                        className={`w-6 h-6 border border-black rounded-md ${
                          isCheckboxCheckedTow ? "bg-Main" : ""
                        }`}
                      />
                      <p>إضافة رسوم التحصيل المالي للدفعة الواحدة</p>
                    </div>
                    {isCheckboxCheckedTow && (
                      <>
                        <h1 className="text-center  my-2 font-semibold">
                          رسوم التحصيل المالي للدفعة الواحدة
                        </h1>
                        <div className="flex justify-center items-center gap-10 mb-5 mt-1">
                          <div className="flex justify-center items-center gap-2">
                            <div className="bg-Main w-fit rounded-full p-[1.2px]">
                              <div
                                className={`${
                                  formData?.fc_commission_type === "MONETARY"
                                    ? "bg-Main"
                                    : "bg-white"
                                } w-[18px] h-[18px] rounded-full border-[3px] border-white`}
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    fc_commission_type: "MONETARY",
                                  });
                                }}
                              />
                            </div>
                            <p className="text-xl font-bold">مبلغ مقطوع</p>
                          </div>
                          <div className="flex justify-center items-center gap-2">
                            <div className="bg-Main w-fit rounded-full p-[1.2px]">
                              <div
                                className={`${
                                  formData?.fc_commission_type === "PERCENTAGE"
                                    ? "bg-Main"
                                    : "bg-white"
                                } w-[18px] h-[18px] rounded-full border-[3px] border-white`}
                                onClick={() => {
                                  setFormData({
                                    ...formData,
                                    fc_commission_type: "PERCENTAGE",
                                  });
                                }}
                              />
                            </div>
                            <p className="text-xl font-bold">
                              نسبة من الملف المالي 
                            </p>
                          </div>
                        </div>
                        {formData?.fc_commission_type === "MONETARY" && (
                          <>
                            <Input
                              type="number"
                              name="fc_commission"
                              value={formData?.fc_commission}
                              onChange={handleChangeInput}
                              title="مبلغ مقطوع"
                            />
                          </>
                        )}
                        {formData?.fc_commission_type === "PERCENTAGE" && (
                          <Input
                            type="number"
                            name="fc_commission"
                            value={formData?.fc_commission}
                            onChange={handleChangeInputNumber}
                            title="نسبة من الملف المالي "
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {selectRef.current?.options[selectRef.current.selectedIndex]
                  ?.dataset.mode !== "other" && (
                  <>
                    <Input
                      name="username"
                      value={formData?.username}
                      onChange={handleChangeInput}
                      title="اسم المستخدم"
                    />
                    <PasswordInput
                      name="password"
                      // value={formData?.password}
                      onChange={handleChangeInput}
                      // onChange={handleChangePassword}
                    />
                    <PasswordInput
                      name="passwordConfirmation"
                      // value={formData?.password}
                      onChange={handleChangeInput}
                      placeholder="تأكيد كلمة السر"
                    />
                    {formData?.passwordError && (
                      <p className="text-red-600">{formData.passwordError}</p>
                    )}
                  </>
                )}

                <Input
                  name="email"
                  value={formData?.email}
                  onChange={handleChangeInput}
                  title="الإيميل"
                />
                <Input
                  name="phone_number"
                  value={formData?.phone_number}
                  onChange={handleChangeInput}
                  title="رقم الهاتف"
                />
                <Input
                  name="salary"
                  value={formData?.salary}
                  onChange={handleChangeInput}
                  title="الراتب"
                />
                <Input
                  name="name"
                  value={formData?.name}
                  onChange={handleChangeInput}
                  title="الاسم"
                />

                {loading ? <Loading /> : ""}

                <ButtonRed
                  name={id !== "add" ? "تعديل" : "اضافة"}
                  onClick={handleSubmitMain}
                  className="py-3 mt-3"
                />
              </>
            )}
          </Col>
        </Row>
      )}
      <Back />
    </div>
  );
};

export default AddUser;
