import { useFilter } from "../../Tools/APIs";

function ArchiveFilter() {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  return (
    <div>
      <select
        value={searchParams.get("in_archive")}
        onChange={(e) => {
          if (!e.target.value) {
            handleParamsDelete("in_archive");
          } else {
            handleParamsClick("in_archive", e.target.value);
          }
        }}
        className="w-full bg-Third rounded-xl py-2 text-center px-5 text-white outline-none"
      >
        <option selected disabled hidden>
          فلترة حسب الأرشفة
        </option>
        <option value="">الكل</option>
        <option value="1">مؤرشفة</option>
        <option value="0">غير مؤرشفة</option>
      </select>
    </div>
  );
}
export default ArchiveFilter;
