import { Col, Container, Row } from "../../Tools/Grid-system";
import {
  Add,
  Back,
  Data,
  Delete,
  PaymentFilter,
  Title,
  Update,
} from "../../components";
import { useFETCH } from "../../Tools/APIs";
import { Link, useLocation, useParams } from "react-router-dom";
import Pagination from "../../Tools/Pagination";
import Loading from "../../Tools/Loading";
import { AiFillPrinter } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { Tooltip } from "react-tooltip";
import { MdBlockFlipped } from "react-icons/md";
import { FaStar } from "react-icons/fa";
function Payment() {
  const { id } = useParams();
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/transactions/${id}/payments${search}`,
    `admin/transactions/${id}/payments`
  );

  return (
    <Container>
      <Title title=" الملفات المالية" link="/transactions" />
      <Title ball title="الدفعات" />
      <Row className="mb-5  px-3">
        <Col xs={4} sm={6}>
          <PaymentFilter />
        </Col>
      </Row>
      <Row className="mb-5 justify-center px-3">
        <Col md={3}>
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 bg-green-400 rounded-sm" />
            <p>الدفعات المقبولة</p>
          </div>
        </Col>
        <Col lg={5} md={6}>
          <div className="flex items-center gap-2">
            <FaStar size={25} className="text-yellow-400" />
            <p>الدفعات المحتوية معلومات الحوالة</p>
          </div>
        </Col>
        <Col lg={5} md={6}>
          <div className="flex items-center gap-2">
            <div className="w-6 h-6 bg-gray-200 rounded-sm" />
            <p>الدفعات الغير مقبولة بعد</p>
          </div>
        </Col>
      </Row>
      <DataTransaction id={id} />
      {isLoading ? <Loading /> : ""}
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row justify="center md:px-3">
          <Col className="overflow-x-auto">
            <table className="w-full  max-md:w-[700px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-Main">
              <thead className="bg-Main text-white h-[70px]">
                <tr>
                  <td className="px-7">رقم</td>
                  <td className="px-7">حالة الدفعة</td>
                  <td className="px-7">تاريخ الدفعة</td>
                  <td className="px-7"> قيمة الدفعة</td>
                  <td className="px-7">قيمة الحوالة</td>
                  <td className="px-7">المبلغ الذي وصل</td>
                  <td className="px-7">العمليات</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e, i) => (
                    <tr
                      key={i}
                      className={`border border-Main
                       ${e.state === 2 ? "bg-green-400" : "bg-gray-200"}`}
                    >
                      <td className="">{e.id}</td>
                      <td className={`font-bold `}>
                        {e.state === 2 ? "مقبولة" : "غير مقبولة بعد"}
                      </td>
                      <td className="">{e.date}</td>
                      <td className="">{e.amount}</td>
                      <td className="">{e.transfer_amount || "___"}</td>
                      <td className="">
                        {e.received_amount === 0
                          ? "غير محدد بعد"
                          : e.received_amount}
                      </td>
                      <td className="flex justify-center items-center gap-3 mt-2">
                        <div className="flex justify-center items-center gap-3">
                          {e.state === 2 && (
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <div id="my-anchor-element">
                                <Link
                                  to={`/transactions/${e.id}/payment/pdf/${e.id}`}
                                >
                                  <AiFillPrinter
                                    size={25}
                                    className="text-gray-600"
                                  />
                                  <Tooltip
                                    anchorSelect="#my-anchor-element"
                                    content="طباعة الفاتورة"
                                  />
                                </Link>
                              </div>
                            </div>
                          )}
                          {e.state === 1 && (
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center`}
                            >
                              <div id="my-anchor-element-4">
                                <MdBlockFlipped
                                  size={25}
                                  className="text-red-700"
                                />
                                <Tooltip
                                  anchorSelect="#my-anchor-element-4"
                                  content="غير مسموح بطباعة الفاتورة"
                                />
                              </div>
                            </div>
                          )}
                          <Update
                            show={true}
                            name={e.name}
                            edit={`/transactions/${id}/payment/${e.id}`}
                          />
                          <div id="my-anchor-element-show">
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <Link
                                to={`/transactions/${id}/payment-details/${e.id}`}
                              >
                                <FaEye size={27} className="text-blue-700" />
                              </Link>
                            </div>
                            <Tooltip
                              anchorSelect="#my-anchor-element-show"
                              content="عرض"
                            />
                          </div>
                          <Delete id={e.id} onClick={() => deleteItem(e)} />
                        </div>
                        {e.has_transfer_info ? (
                          <FaStar size={25} className="text-yellow-400" />
                        ) : (
                          <FaStar
                            size={25}
                            className={`${
                              e.state === 2 ? "text-green-400" : "text-gray-200"
                            }`}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      <Add link={`/transactions/${id}/payment/add`} />
      <Back />
    </Container>
  );
}
export default Payment;

export const DataTransaction = ({ id }) => {
  const { data: dataTransaction } = useFETCH(`admin/transactions/${id}`);

  return (
    <Row className="mb-5 justify-center px-3">
      <Col lg={4} xs={6}>
        <Data
          name=" المبلغ الكامل "
          text={dataTransaction?.data.data.total_cost}
        />
      </Col>
      <Col lg={4} xs={6}>
        <Data
          name=" مبلغ الحسم"
          text={dataTransaction?.data.data.discounted_amount}
        />
      </Col>
      <Col lg={4} xs={6}>
        <Data
          name=" المبلغ بعد الحسم"
          text={dataTransaction?.data.data.total_cost_after_discount}
        />
      </Col>
      <Col lg={4} xs={6}>
        <Data
          name=" المبلغ المدفوع "
          text={dataTransaction?.data.data.paid_amount}
        />
      </Col>
      <Col lg={4} xs={6}>
        <Data
          name=" المبلغ المتبقي "
          text={dataTransaction?.data.data.remaining_amount}
        />
      </Col>
      <Col lg={4} xs={6}>
        <Data
          name=" اسم الطالب"
          text={dataTransaction?.data.data.student.name}
        />
      </Col>
      <Col lg={4} xs={6}>
        <Data name=" التخصص" text={dataTransaction?.data.data.specialty.name} />
      </Col>
      <Col lg={4} xs={6}>
        <Data name=" تاريخ " text={dataTransaction?.data.data.date} />
      </Col>
      <Col lg={4} xs={6}>
        <Data
          name="حالة الملف المالي "
          text={
            dataTransaction?.data.data.is_completed ? "مكتملة" : "غير مكتملة"
          }
        />
      </Col>
    </Row>
  );
};
