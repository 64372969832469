import { useLocation } from "react-router-dom";
import { useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Add, Back, Table, Title } from "../../components";

function Payments() {
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/payment/methods?page=${search}`,
    `admin/payment/methods`
  );
  return (
    <>
      <Title title="طرق الدفع" />
      {isLoading ? <Loading /> : ""}
      <Table
        thead={["الاسم"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[{ name: "name" }]}
        funDelete={deleteItem}
        edit="payments"
      />
      <Add link={`/payments/add`} />
      <Back />
    </>
  );
}
export default Payments;
