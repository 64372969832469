function FilterButton({ name, onClick, className }) {
  return (
    <div>
      <div
        className={`border-2 border-Third bg-white text-Third rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-3
                  ${className}`}
        onClick={onClick}
      >
        {name}
      </div>
    </div>
  );
}
export default FilterButton;
