import Logo from "../../images/logo.png";
import { useLOGIN } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { ButtonRed, Input, PasswordInput } from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Login = () => {
  const { handleSubmit, error, loading, handleChange, setFormData, formData } =
    useLOGIN();
  const handleSubmitMain = () => {
    handleSubmit("admin/login");
  };
  useEffect(() => {
    if (sessionStorage.getItem("fcm_token")) {
      setFormData({
        ...formData,
        fcm_token: sessionStorage.getItem("fcm_token"),
      });
    }
  }, []);
  document.onkeyup = function (e) {
    if (e.key === "Enter") {
      handleSubmitMain();
    }
  };
  return (
    <Container>
      <Row>
        <div className="bg fixed w-full h-full bg-white top-0 left-0 z-[99999999999] flex items-center justify- ext-center">
          <Col
            md={5}
            className="mx-auto border-2 bg-white border-Secondary rounded-xl p-5 w-full"
          >
            <img src={Logo} alt="" className="w-[300px] mx-auto" />
            <h2 className="text-xl text font-semibold">تسجيل الدخول</h2>
            <Input
              type="name"
              name="name_or_email"
              onChange={handleChange}
              title="اسم المستخدم أو البريد الالكتروني"
            />
            <PasswordInput name="password" onChange={handleChange} />
            <div className="text-red-600 ">{error}</div>
            <p className="flex justify-between text-gray-700 my-3">
              <Link to="/forget-password" className="underline text-Main mx-1">
                هل نسيت كلمة المرور
              </Link>
            </p>
            {loading ? <Loading /> : ""}

            <ButtonRed
              name="تسجيل دخول"
              onClick={handleSubmitMain}
              className="w-[70%] py-3 mx-auto"
            />
          </Col>
        </div>
      </Row>
    </Container>
  );
};

export default Login;
