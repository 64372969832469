function Selected({
  children,
  text,
  title,
  name,
  value,
  onChange,
  className,
  sss,
  optionValue,
}) {
  return (
    <div className="">
      {text && <h1 className={`text-start mx-1 mt-2 ${sss}`}>{text}</h1>}
      <select
        name={name}
        value={value}
        onChange={onChange}
        className={`w-full border-2 border-Secondary py-2 rounded-xl  outline-none text-center mx-auto ${className}`}
      >
        <option value="" disabled selected hidden>
          {title}
        </option>
        {optionValue && (
          <option value="1" selected>
            غير مقبولة
          </option>
        )}
        {children}
      </select>
    </div>
  );
}
export default Selected;
