import { useFETCH } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, Data, Title } from "../../components";
import user from "../../svg/user_1144709.svg";

function Profile() {
  const { data, isLoading } = useFETCH(`admin/profile`);
  let dataAll = data?.data.data;

  return (
    <div>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Title title="الحساب الشخصي" />
        <img src={user} alt="" className="w-[100px] mx-auto mb-5" />
        <Col
          sm={14}
          className="bg-white mx-auto border-2 border-Secondary py-5 px-3 rounded-xl"
        >
          <Row className="justify-center">
            <Col md={7}>
              <Data name="الدور" text={dataAll?.role?.display} />
            </Col>
            <Col md={7}>
              <Data name="الاسم" text={dataAll?.name} />
            </Col>
            <Col md={7}>
              <Data name="رقم الهاتف" text={dataAll?.phone_number} />
            </Col>
            <Col md={7}>
              <Data name="الإيميل" text={dataAll?.email} />
            </Col>
            <Col md={7}>
              <Data name="الراتب" text={dataAll?.salary} />
            </Col>
            <Col md={7}>
              <Data name="اسم المستخدم" text={dataAll?.username} />
            </Col>
            <Col md={7}>
              <Data
                name="تاريخ الإنشاء"
                text={dataAll?.created_at.substring(0, 10)}
              />
            </Col>
            <Col md={7}>
              <Data name="الرصيد" text={dataAll?.balance} />
            </Col>
            <Col md={7}>
              <Data name="العمولة" text={dataAll?.commission} />
            </Col>
            <Col md={7}>
              <Data name="نوع العمولة" text={dataAll?.commission_type} />
            </Col>
            <Col md={7}>
              <Data name="عمولة التحصيل" text={dataAll?.fc_commission} />
            </Col>
            <Col md={7}>
              <Data
                name="نوع عمولة اللتحصيل"
                text={dataAll?.fc_commission_type}
              />
            </Col>
          </Row>
        </Col>
      </Container>
      <Back />
    </div>
  );
}
export default Profile;
