function Profit({ children, name, mony, className }) {
  return (
    <div
      className={`flex justify-between items-center text-white bg-black py-5 px-3 rounded-xl  font-bold ${className}`}
    >
      <div>
        <p>{name}</p>
        <p>{mony}</p>
      </div>
      <div>{children}</div>
    </div>
  );
}
export default Profit;
