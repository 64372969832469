import { useLocation } from "react-router-dom";
import { useFETCH } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import { Add, Back, Table, Title } from "../../components";

const AcademicDegrees = () => {
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/degrees` + search,
    `admin/degrees`
  );
  return (
    <>
      <Title title="الدرجات العلمية" />
      {isLoading ? <Loading /> : ""}
      <Table
        thead={["الاسم"]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[{ name: "name" }]}
        funDelete={deleteItem}
        edit="academic-degrees"
      />
      <Add link={`/academic-degrees/add`} />
      <Back />
    </>
  );
};

export default AcademicDegrees;
