import React, { useEffect } from "react";
import { useContextHook } from "../../Context/ContextOPen";

const SuccessMessage = () => {
  const { successMessage, setSuccessMessage } = useContextHook();

  useEffect(() => {
    if (successMessage) {
      setTimeout(() => {
        setSuccessMessage([]);
      }, 4000);
    } else {
      setSuccessMessage([]);
    }
  }, [successMessage]);
  return (
    <>
      {
        <div
          className={`fixed transition-all left-7 bg-whit  shadow-lg z-[999999999999] rounded-xl overflow-hidden ${
            successMessage.length !== 0 ? "bottom-10 " : "-bottom-full"
          }`}
          onClick={() => setSuccessMessage([])}
        >
          <div className="bg-green-500 text-white font-semibold py-5 px-7">
            {successMessage?.map((error, index) => {
              return (
                <div key={index} className="flex items-center">
                  <span className="w-2 h-2 rounded-full bg-white mx-2"></span>
                  <p className="mb-2">{error}</p>
                </div>
              );
            })}
          </div>
        </div>
      }
    </>
  );
};

export default SuccessMessage;
