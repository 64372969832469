import { Tooltip } from "react-tooltip";
import { useFETCH, useFilter } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Add, Back, Delete, Search, Title, Update } from "../../components";
import { FaEye } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { BsWalletFill } from "react-icons/bs";
import Pagination from "../../Tools/Pagination";

function Beneficiaries() {
  const { search } = useLocation();
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/users?role=beneficiary${search ? "&" + search.substring(1) : ""}`,
    `admin/users`
  );
  return (
    <>
      <Container>
        <Title title="المستفيدين" />
        <Col md={3}>
          <Search />
        </Col>

        {isLoading ? <Loading /> : ""}
      </Container>
      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row justify="center md:px-3">
          <Col className="overflow-x-auto">
            <table className="w-full  max-md:w-[700px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-4 border-white">
              <thead className="bg-Main text-white h-[70px]">
                <tr>
                  <td className="px-7">رقم</td>
                  <td className="px-7">الاسم</td>
                  <td className="px-7">نوع الحساب </td>
                  <td className="px-7">الرصيد</td>
                  <td className="px-7">العمليات</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e, i) => (
                    <tr key={i} className="border border-Main bg-white">
                      <td className="">{e.id}</td>
                      <td className="">{e.name}</td>
                      <td className="">{e.role?.display}</td>
                      <td className="">{e.balance}</td>
                      <td className="flex justify-center items-center gap-3 mt-2">
                        <div className="flex justify-center items-center gap-3">
                          <Update
                            name={e.name}
                            edit={`/beneficiaries/${e.id}`}
                          />
                          <div id="my-anchor-element-wallet">
                            <div
                              className={` w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <Link to={`/beneficiaries/wallet/${e.id}`}>
                                <BsWalletFill
                                  size={30}
                                  className="text-sky-950"
                                />
                              </Link>
                            </div>
                            <Tooltip
                              anchorSelect="#my-anchor-element-wallet"
                              content="المحفظة"
                            />
                          </div>
                          <div id="my-anchor-element-show">
                            <div
                              className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                            >
                              <Link to={`/beneficiaries/info/${e.id}`}>
                                <FaEye size={27} className="text-blue-700" />
                              </Link>
                            </div>
                            <Tooltip
                              anchorSelect="#my-anchor-element-show"
                              content="عرض التفاصيل"
                            />
                          </div>
                          {e.name !== "آدمن رئيسي" && !e.is_fixed ? (
                            <Delete
                              id={e.id}
                              name={e.display || e.name}
                              onClick={() => deleteItem(e)}
                            />
                          ) : (
                            <div className="w-10 h-10">
                              <div className="w-[30px] h-[30px]" />
                            </div>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      <Add link={`/beneficiaries/add`} />
      <Back />
    </>
  );
}
export default Beneficiaries;
