import { BiMenu } from "react-icons/bi";
import Logo from "../../images/logo.png";
import { useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
import { ButtonRed } from "../../components";
import { logout } from "../../Tools/APIs";
import NotificationNew from "./NotificationNew";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { changeMenu } = useContextHook();
  const [sure, setSure] = useState(false);
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 bg/black/60 z-40 flex justify-center items-center max-sm:w-full`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[400px] max-w-[500px] min-h-[200px]">
              <p className="font-semibold text-xl text-center py-7">
                هل انت متأكد من تسجيل الخروج ؟
              </p>
              <div className="flex items-center justify-center gap-3 m-5">
                <ButtonRed
                  className="py-3 !px-"
                  onClick={() => {
                    logout("admin/logout");
                  }}
                  name="نعم"
                />
                <ButtonRed
                  className="mx-2 border !px-2  font-semibold !bg-white !text-Secondary hover:!bg-Secondary hover:!text-white rounded-xl ml-5"
                  onClick={() => setSure(false)}
                  name=" إلغاء"
                />
              </div>
            </div>
          </div>
        </>
      )}
      <div className="container mx-auto sticky top-0 z-[30] bg-[#F9F9F9] flex items-center justify-between pt-2 flex-wrap mb-5 pb-2 border-b-2 border-Secondary">
        <div>
          <BiMenu
            size={35}
            className="text-Secondary cursor-pointer"
            onClick={changeMenu}
          />
        </div>
        <Link to="/">
          <img src={Logo} alt="" className="w-40" />
        </Link>
        <div className="max-sm:flex-1">
          <div className="flex gap-9 mx-3 justify-between   ">
            <NotificationNew />
            <div
              className={`bg-[#004B60] rounded-xl hover:bg-opacity-70 text-center font-semibold text-white cursor-pointer w-fit px-5 py-3`}
              onClick={() => setSure(true)}
            >
              تسجيل خروج
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
