import { AiOutlineEdit } from "react-icons/ai";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useClose } from "../../Tools/APIs";
const Update = ({ edit, show }) => {
  const { mouse, open, setOpen } = useClose();
  return (
    <>
      <div ref={mouse} className="w-fit relative">
        {open && (
          <>
            <div
              className={`absolute bg-white rounded-xl border border-Secondary -top-10 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center`}
            >
              <div className="flex items-center justify-center m-5">
                <div className="flex gap-2 mx-3">
                  <Link to={`${edit}`}>
                    <div className="px-3 py-2 bg-Third my-2 rounded-lg text-white cursor-pointer hover:bg-opacity-75">
                      نعم
                    </div>
                  </Link>
                </div>

                <button
                  onClick={() => setOpen(false)}
                  className="px-3 py-2 bg-Secondary my-2 rounded-lg text-white cursor-pointer hover:bg-opacity-75"
                >
                  تراجع
                </button>
              </div>
            </div>
          </>
        )}
        <div id="my-anchor-element-edit">
          <div
            onClick={() => setOpen(true)}
            className={`${
              sessionStorage.getItem("role_name") ===
                "financial_collection_officer" && !show
                ? "hidden"
                : ""
            }  w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
          >
            <AiOutlineEdit size={30} className="text-green-700" />
          </div>
          <Tooltip anchorSelect="#my-anchor-element-edit" content="تعديل" />
        </div>
      </div>
    </>
  );
};

export default Update;
