import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Back, ButtonRed, Input, Selected, Title } from "../../components";
import { FaPlusCircle } from "react-icons/fa";
import { useContextHook } from "../../Context/ContextOPen";
const AddProcesses = () => {
  const { id, idItems } = useParams();
  const { successPost } = useContextHook();
  let today = new Date();
  let dates = today.toISOString().substring(0, 10);
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({ date: dates, category_id: idItems });
  const { data: dataBeneficiaries } = useFETCH(
    `admin/users?role=beneficiary&paginate=0&test=${successPost}`
  );
  const { data: dataAdmins } = useFETCH(`admin/users?paginate=0`);
  const { data: dataCategories } = useFETCH(
    `admin/categories?paginate=0&operation=1`
  );
  const { data } = useFETCH(id === "add" ? "" : `admin/operations/${id}`);
  const handleSubmitMain = () => {
    handleSubmit(id === "add" ? "admin/operations" : `admin/operations/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        category_id: idItems ? idItems : dataOld?.category?.id,
        amount: dataOld?.amount,
        date: dataOld?.date,
        user_id: dataOld?.user?.id,
      });
    id === "add" &&
      setFormData({
        ...formData,
        role_name: "beneficiary",
        user_id: dataBeneficiaries?.data.data[0]?.id,
      });
  }, [dataOld, dataBeneficiaries?.data.data]);
  return (
    <>
      <TitleProcesses id={id} idItems={idItems} />
      <Row>
        <Col
          lg={8}
          sm={14}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto"
        >
          <Input
            type="date"
            name="date"
            value={formData?.date ? formData.date : dates}
            onChange={handleChangeInput}
            title="ادخل التاريخ"
          />
          <Input
            type="number"
            name="amount"
            value={formData?.amount}
            onChange={handleChangeInput}
            title="ادخل المبلغ"
          />
          {id !== "add" ? (
            ""
          ) : (
            <>
              <Row className="">
                <Col col={2}>
                  <AddBeneficiaries />
                </Col>
                <Col col={5} className="flex justify-center items-center gap-2">
                  <input
                    type="radio"
                    name="role_name"
                    value={"beneficiary"}
                    checked={formData?.role_name === "beneficiary"}
                    onChange={handleChangeInput}
                    className="w-5 h-5"
                  />
                  <p className="text-xl font-bold">المستفيد</p>
                </Col>
                <Col col={5} className="flex justify-center items-center gap-2">
                  <input
                    type="radio"
                    name="role_name"
                    value={"admins"}
                    checked={formData?.role_name !== "beneficiary"}
                    onChange={handleChangeInput}
                    className="w-5 h-5"
                  />
                  <p className="text-xl font-bold">كادر الأكاديمية</p>
                </Col>
              </Row>
              {formData?.role_name !== "beneficiary" && (
                <Select
                  options={dataAdmins?.data.data.map((e) => {
                    return { value: e.id, label: e.name };
                  })}
                  name="user_id"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      user_id: e.value,
                    })
                  }
                />
              )}
              {formData?.role_name === "beneficiary" && (
                <Select
                  value={{
                    value: dataBeneficiaries?.data.data[0]?.id,
                    label: dataBeneficiaries?.data.data[0]?.name,
                  }}
                  options={dataBeneficiaries?.data.data.map((e) => {
                    return { value: e.id, label: e.name };
                  })}
                  name="user_id"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      user_id: e.value,
                    })
                  }
                />
              )}
            </>
          )}

          {!idItems && (
            <Selected
              text="الصنف"
              title="اختر الصنف"
              name="category_id"
              value={formData?.category_id}
              onChange={handleChangeInput}
            >
              {dataCategories?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.display}
                </option>
              ))}
            </Selected>
          )}

          {loading ? <Loading /> : ""}

          <ButtonRed
            name={id !== "add" ? "تعديل" : "اضافة"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      <Back />
    </>
  );
};

export default AddProcesses;
const TitleProcesses = ({ id, idItems }) => {
  const { data } = useFETCH(idItems && `admin/categories/${idItems}`);
  return (
    <>
      {idItems ? (
        <>
          <Title title={data?.data.data.name} link="/items" />
          <Title
            title={id === "add" ? "أضف مصروف" : "تعديل المصروف"}
            className="mt-5"
          />
        </>
      ) : (
        <Title
          title={id === "add" ? "أضف مصروف" : "تعديل المصروف"}
          className="mt-5"
        />
      )}
    </>
  );
};
const AddBeneficiaries = () => {
  const {
    handleSubmit,
    handleChangeInput,
    successfulResponse,
    setSuccessfulResponse,
    loading,
  } = usePOST({});
  return (
    <>
      <div>
        {successfulResponse && (
          <>
            <div
              className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
              onClick={() => setSuccessfulResponse(false)}
            ></div>
            <div
              className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center `}
            >
              {loading ? <Loading /> : ""}
              <div className="bg-white z-[60] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
                <p className="font-semibold text-xl text-center py-7 w-[90%] mx-auto">
                  <Input
                    name="name"
                    onChange={handleChangeInput}
                    title="ادخل اسم المستفيد"
                  />
                </p>
                <div className="flex items-center justify-center m-5">
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() =>
                        handleSubmit("admin/users?role=beneficiary", "", true)
                      }
                    >
                      إضافة
                    </div>
                  </div>
                  <div className="flex gap-9 mx-3">
                    <div
                      className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                      onClick={() => setSuccessfulResponse(false)}
                    >
                      إلغاء
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="w-fit">
          <FaPlusCircle
            className="cursor-pointer text-Main m-1 font-bold"
            size={35}
            onClick={() => setSuccessfulResponse(true)}
          />
        </div>
      </div>
    </>
  );
};
