import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";

const AddAcademicDegrees = () => {
  const { id } = useParams();
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({});
  const { data } = useFETCH(id === "add" ? "" : `admin/degrees/${id}`);
  let dataOld = data?.data.data;
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "admin/degrees" : `admin/degrees/${id}`);
  };
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataOld?.name,
      });
  }, [dataOld]);
  return (
    <>
      <Title title="الدرجات العلمية" className="mt-5" />
      <Row className="h-[70vh] flex justify-center items-center">
        <Col
          md={5}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto "
        >
          <Input
            name="name"
            value={formData?.name}
            onChange={handleChangeInput}
            title="ادخل درجة العلمية"
          />
          {loading ? <Loading /> : ""}
          <ButtonRed
            name={id === "add" ? "اضافة" : "تعديل"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      <Back />
    </>
  );
};

export default AddAcademicDegrees;
