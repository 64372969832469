import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Input } from "../../components";
import Loading from "../../Tools/Loading";

const EditItems = () => {
  const { id } = useParams();
  const { handleChangeInput, handleSubmit, setFormData, formData, loading } =
    usePOST({});
  const { data } = useFETCH(id === "add" ? "" : `admin/categories/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "admin/categories" : `admin/categories/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        display: dataOld?.display,
      });
  }, [dataOld]);
  return (
    <div className="">
      <Row className="h-[70vh] flex justify-center items-center">
        <Col
          md={5}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto"
        >
          <Input
            name="display"
            value={formData?.display}
            onChange={handleChangeInput}
            title="الصنف"
          />
          <ButtonRed
            name={id !== "add" ? "تعديل" : "اضافة"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      {loading ? <Loading /> : ""}

      <Back />
    </div>
  );
};

export default EditItems;
