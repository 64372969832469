import DeleteParamsAll from "./DeleteParamsAll";
import SingleButFiler from "./SingleButFiler";
function StudentFilter() {
  return (
    <>
      <DeleteParamsAll />
      <SingleButFiler value="1" name="completed" title="الطلاب الفعالين" />
      <SingleButFiler value="0" name="completed" title="الطلاب الغير فعالين" />
      <SingleButFiler value="1" name="trashed" title=" الطلاب المحذوفين" />
    </>
  );
}
export default StudentFilter;
