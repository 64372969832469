import {
  Add,
  ArchiveFilter,
  Back,
  DateFilter,
  Delete,
  Selected,
  Title,
  Update,
} from "../../components";
import { Col, Container, Row } from "../../Tools/Grid-system";
import { useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { Link, useLocation, useParams } from "react-router-dom";
import UpLoad from "../../components/Delete/UpLoad";
import Download from "../../components/Delete/Download";
import { BsWalletFill } from "react-icons/bs";
import { Tooltip } from "react-tooltip";
import { useEffect } from "react";

function Processes() {
  const { idItems } = useParams();
  const { searchParams, handleParamsClick } = useFilter();
  const { search } = useLocation();
  const { data: dataAdmin } = useFETCH(`admin/users?paginate=0`);
  const { data: dataBeneficiaries } = useFETCH(
    `admin/users?role=beneficiary&paginate=0`
  );
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/operations${search}`,
    `admin/operations`
  );
  useEffect(() => {
    handleParamsClick("in_archive", 0);
  }, []);
  return (
    <>
      <TitleProcesses searchParams={searchParams} />
      <Container>
        <Row className="items-center">
          <Col xs={7} md={4} lg={3}>
            <DateFilter />
          </Col>
          <Col xs={7} md={4} lg={3}>
            <ArchiveFilter />
          </Col>
          <Col xs={7} md={4} lg={3}>
            <Selected
              title="فلترة حسب الكادر"
              value={searchParams.get("user_id")}
              onChange={(e) => handleParamsClick("user_id", e.target.value)}
              className="border-none w-full mb-0 bg-Third text-center px-5 text-white outline-none"
            >
              <option value="">الكل</option>
              {dataAdmin?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Selected>
          </Col>
          <Col xs={7} md={4} lg={3}>
            <Selected
              title="الجهة المستفيدة"
              value={searchParams.get("user_id")}
              onChange={(e) => handleParamsClick("user_id", e.target.value)}
              className="border-none w-full bg-Third text-center px-5 text-white outline-none"
            >
              <option value="">الكل</option>
              {dataBeneficiaries?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Selected>
          </Col>
        </Row>
        {isLoading ? <Loading /> : ""}
      </Container>

      <Pagination
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
      >
        <Row justify="center md:px-3">
          <Col className="overflow-x-auto">
            <table className="w-full  max-md:w-[700px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-2 border-Main">
              <thead className="bg-Main text-white h-[70px]">
                <tr>
                  <td className="px-7">رقم</td>
                  <td className="px-7">التاريخ</td>
                  <td className="px-7">المبلغ</td>
                  <td className="px-7">صرفها</td>
                  <td className="px-7">تم صرفها ل</td>
                  <td className="px-7">الصنف</td>
                  <td className="px-7">الحالة</td>
                  <td className="px-7">العمليات</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e, i) => (
                    <tr key={i} className="border border-Main bg-white">
                      <td className="">{e.id}</td>
                      <td className="font-bold ">{e.date}</td>
                      <td className="font-semibold py-7">{e.amount}</td>
                      <td className="">{e.added_by_admin?.name || "___"}</td>
                      <td className="">
                        {/* {e.admin?.name ? e.admin?.name : e.beneficiary?.name} */}
                        {e.user?.name}
                      </td>
                      <td className="">{e.category?.display}</td>
                      {e.status === "PENDING" ? (
                        <td className="text-red-500 font-semibold">
                          {"غير مصروفة"}
                        </td>
                      ) : e.status === "COMPLETED" ? (
                        <td className="text-green-500 font-semibold">
                          {"مصروفة"}
                        </td>
                      ) : (
                        ""
                      )}

                      <td className="flex justify-center items-center gap-3 h-full">
                        <div className="flex justify-center items-center gap-3 mt-4">
                          <>
                            <div id="my-anchor-element-wallet">
                              <div
                                className={` w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                              >
                                <Link
                                  to={`/processes/wallet/${e.balance_log.user_id}?model_id=${e.balance_log.id}`}
                                >
                                  <BsWalletFill
                                    size={30}
                                    className="text-sky-950"
                                  />
                                </Link>
                              </div>
                              <Tooltip
                                anchorSelect="#my-anchor-element-wallet"
                                content="الذهاب الى السجل الخاص بالمصروف"
                              />
                            </div>
                          </>

                          {e.status !== "COMPLETED" ? (
                            <>
                              <Update
                                name={e.name}
                                edit={
                                  idItems
                                    ? `/items/${idItems}/processes/${e.id}`
                                    : `/processes/${e.id}`
                                }
                              />

                              <Delete id={e.id} onClick={() => deleteItem(e)} />
                            </>
                          ) : (
                            <div className="w-24" />
                          )}
                          {e.in_archive === 1 ? (
                            <UpLoad name="operations" iddd={`${e.id}`} />
                          ) : (
                            ""
                          )}
                          {e.in_archive === 0 ? (
                            <Download name="operations" iddd={`${e.id}`} />
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      <div
        className={`bg-Third rounded-xl text-center font-semibold text-white w-[250px] mx-auto py-3`}
      >
        {data?.data.total_amount}
      </div>
      <Add
        link={idItems ? `/items/${idItems}/processes/add` : "/processes/add"}
      />
      <Back />
    </>
  );
}
export default Processes;

const TitleProcesses = ({ searchParams }) => {
  const { data } = useFETCH(
    searchParams.has("category_id") &&
      `admin/categories/${searchParams.get("category_id")}`
  );
  return (
    <>
      {searchParams.has("category_id") ? (
        <>
          <Title title={data?.data.data.name} link="/items" />
          <Title ball title="المصاريف" />
        </>
      ) : (
        <Title title="المصاريف" />
      )}
    </>
  );
};
