import { Link, useLocation } from "react-router-dom";
import { useFETCH, useFilter } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { Back, Delete, Title } from "../../components";
const Notifications = () => {
  const { search } = useLocation();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/notifications${search}`,
    "admin/notifications"
  );
  
  return (
    <section className="py-4 min-h-[50vh]">
      <Title title="الإشعارات" />
      <>
        {isLoading ? <Loading /> : ""}
        {data?.data.data.data.length === 0 ? (
          <div className="h-[60vh] flex justify-center items-center text-2xl font-semibold text-center">
            <p className="border-2 border-Secondary rounded-xl px-3 py-8 bg-white">
              لا يوجد إشعارات ليتم عرضها
            </p>
          </div>
        ) : (
          <Pagination
            pageCount={Math.ceil(
              data?.data.data.total / data?.data.data.per_page
            )}
          >
            <Row justify="center">
              <Col
                md={10}
                className="bg-white rounded-xl space-y-1 overflow-hidden"
              >
                {data?.data.data.data.map((e, i) => (
                  <div
                    key={i}
                    className={`${
                      +e.has_read !== 1 ? " bg-green-100" : ""
                    } flex justify-between items-start   border-b p-2 border-Third `}
                  >
                    {e.clickable === 1 ? (
                      <Link
                        to={
                          e.state === 1
                            ? "/my-info/" + e.model_id
                            : e.state === 2
                            ? `/transactions/${e?.additional_data?.transaction_id}/payment-details/${e.model_id}`
                            : e.state === 3
                            ? "/my-info/" + e.model_id
                            : e.state === 4
                            ? "/my-info/" + e.model_id
                            : e.state === 5
                            ? "/my-info/" + e.model_id
                            : ""
                        }
                      >
                        <div className="text- text-Main font-bold py-2">
                          {e.title}
                        </div>
                        <div className="">{e.description}</div>
                        <div className="  text-sm font-semibold pt-2">
                          {e.updated_at.substring(0, 20)}
                        </div>
                      </Link>
                    ) : (
                      <>
                        <div className="text- text-Main font-bold py-2">
                          {e.title}
                        </div>
                        <div className="mb-3">{e.description}</div>
                        <div className="  text-sm font-semibold pt-2">
                          {e.updated_at.substring(0, 20)}
                        </div>
                      </>
                    )}
                  </div>
                ))}
              </Col>
            </Row>
          </Pagination>
        )}
      </>
      <Back />
    </section>
  );
};

export default Notifications;
