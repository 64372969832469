import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Input, Title } from "../../components";
import Loading from "../../Tools/Loading";

const AddBeneficiaries = () => {
  const { id } = useParams();
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    formData,
    loading,
    error,
  } = usePOST({});
  const { data } = useFETCH(
    id === "add" ? "" : `admin/users/${id}?role=beneficiary`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      id === "add"
        ? "admin/users?role=beneficiary"
        : `admin/users/${id}?role=beneficiary`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataOld?.name,
      });
  }, [dataOld]);
  return (
    <>
      <Title title="أضف مستفيد" className="mt-5" />
      <Row className="h-[70vh] flex justify-center items-center">
        <Col
          md={5}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto "
        >
          <Input
            name="name"
            value={formData?.name}
            onChange={handleChangeInput}
            title="ادخل اسم المستفيد"
          />
          {loading ? <Loading /> : ""}
          <ButtonRed
            name={id !== "add" ? "تعديل" : "اضافة"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      <Back />
    </>
  );
};

export default AddBeneficiaries;
