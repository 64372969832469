import { GrDocumentTransfer } from "react-icons/gr";
import { IoIosTimer } from "react-icons/io";
import { RiFolderTransferLine } from "react-icons/ri";
import { useLocation, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useFETCH, useFilter } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import Pagination from "../../Tools/Pagination";
import { Back, DateFilter, Delete, WalletFilter } from "../../components";
import Giving from "../../components/Delete/Giving";
import GivingAll from "../../components/Delete/GivingAll";
import Restore from "../../components/Delete/Restore";
function Wallet() {
  const { id } = useParams();
  const { search } = useLocation();
  const { searchParams } = useFilter();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/users/${id}/balance/logs?role=beneficiary${
      search ? "&" + search.substring(1) : ""
    }`,
    `admin/users/${id}/balance/logs`
  );
  return (
    <Container>
      <Col xs={7} md={4} lg={3}>
        <DateFilter />
      </Col>
      <Row>
        <WalletFilter />
      </Row>
      <Row className="justify-center">
        <Col>
          <div
            className={`flex items-center gap-1 w-[300px] mx-auto font-bold text-Secondary mt-3 `}
          >
            <p className="">الرصيد الحالي :</p>
            <p className="font-normal text-Third flex-1">
              {data?.data?.total_balance}
            </p>
          </div>
        </Col>
      </Row>
      <Pagination
        pageCount={Math.ceil(data?.data.data?.total / data?.data.data.per_page)}
      >
        <Row justify="center md:px-3">
          <div className="!w-[50px] rounded-sm !mt-4 mx-auto">
            <GivingAll id={id} />
          </div>
          <Col className="overflow-x-auto">
            <table className="w-full  md:px-3 max-lg:w-[850px] mx-auto my-5 text-center rounded-2xl overflow-hidden border-4 border-Secondary">
              <thead className="bg-Main text-white h-[70px]">
                <tr>
                  <td className="px-7">رقم</td>
                  <td className="px-7">المبلغ</td>
                  <td className="px-7">الرصيد الجديد</td>
                  <td className="px-7">النوع</td>
                  <td className="px-7">حالة السجل</td>
                  <td className="px-7">تاريخ الإنشاء</td>
                  <td className="px-7">السبب</td>
                  <td className="px-7">العمليات</td>
                </tr>
              </thead>
              <tbody className="">
                {isLoading ? (
                  <Loading />
                ) : (
                  data?.data.data.data.map((e, i) => (
                    <tr key={i} className="border border-Main bg-white">
                      <td className="">{e.id}</td>
                      <td
                        className={`${
                          e.amount_type === "CHARGE"
                            ? "text-green-500"
                            : "text-red-600"
                        }  font-bold`}
                      >
                        {e.amount_type === "CHARGE" ? "+" : "-"} {e.amount}
                      </td>
                      <td className="">{e.balance_after}</td>
                      <td className="">{e.amount_type_display}</td>
                      <td className="flex items-center justify-center gap-2 relative bottom-2">
                        <p>
                          {e.status === "PENDING" && <IoIosTimer size={20} />}
                        </p>
                        {e.status_display}
                      </td>
                      <td className="">{e.created_at.substring(0, 10)}</td>
                      <td className="">{e.reason}</td>
                      <td className="px-7 flex justify-center items-center gap-3">
                        {searchParams.get("trashed") === "1" && (
                          <Restore id={id} idd={e.id} name={e.id} />
                        )}
                        {searchParams.get("trashed") !== "1" && (
                          <Delete
                            id={e.id}
                            name={e.display || e.name}
                            onClick={() => deleteItem(e)}
                          />
                        )}
                        <div>
                          {e.status === "PENDING" ? (
                            <Giving name="COMPLETED" id={id} idd={e.id}>
                              <div id="my-anchor-element-2">
                                <RiFolderTransferLine
                                  className="text-purple-500"
                                  size={30}
                                />
                                <Tooltip
                                  anchorSelect="#my-anchor-element-2"
                                  content="تسليم السجل"
                                />
                              </div>
                            </Giving>
                          ) : (
                            <Giving
                              cancel="التراجع عن"
                              name="PENDING"
                              id={id}
                              idd={e.id}
                            >
                              <div id="my-anchor-element">
                                <GrDocumentTransfer size={25} />
                                <Tooltip
                                  anchorSelect="#my-anchor-element"
                                  content="إلغاء تسليم السجل"
                                />
                              </div>
                            </Giving>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </Col>
        </Row>
      </Pagination>
      <Back />
    </Container>
  );
}
export default Wallet;
