function Input({ title, name, value, type, onChange, ref, readOnly }) {
  return (
    <div className="my-3 px-1">
      <h1 className="text-start mb-2 ">{title}</h1>
      <input
        ref={ref}
        type={type || "text"}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={title}
        autocomplete="off"
        readOnly={readOnly}
        className={
          readOnly
            ? "w-full border-2 m-1 py-3 rounded-xl"
            : "w-full border-2 border-Secondary m-1 py-3 rounded-xl"
        }
      />
    </div>
  );
}
export default Input;
