import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useContextHook } from "../../Context/ContextOPen";
import { useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Container } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import { Add, Back, ButtonRed, Table, Title } from "../../components";

function StudentTransactions() {
  const { id } = useParams({});
  const { setSuccessMessage } = useContextHook();
  const { data: dataStudent } = useFETCH(`admin/students/${id}`);
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/transactions?student_id=${id}`,
    `admin/transactions`
  );
  const { handleSubmit, dataPlayer, loading } = usePOST({});
  useEffect(() => {
    if (dataPlayer?.data?.data.success) {
      setSuccessMessage(["تم ارسال التذكير الى ايميل الطالب"]);
    }
  }, [dataPlayer?.data.data.success]);
  return (
    <div>
      <Container>
        <Title
          title={`${dataStudent?.data?.data.name || ""}  » الملف المالي`}
        />
        {isLoading || loading ? <Loading /> : ""}
      </Container>
      {data?.data.data.data.length <= 0 && (
        <Col xs={5} md={3} className="mx-auto">
          <ButtonRed
            link={`/students/${id}/transactions/add`}
            name="انشاء ملف المالي"
          />
        </Col>
      )}
      <Add link={`/students/${id}/transactions/add`} />
      <Table
        thead={[
          "تاريخ ",
          "التخصص",
          "قيمة ",
          "القيمة  بعد الخصم",
          "المبلغ  المدفوع",
          "المبلغ  المتبقي",
          "الحالة ",
        ]}
        tData={data?.data.data.data}
        tbody={[
          { name: "date" },
          { name: "specialty", object: "name" },
          { name: "total_cost" },
          { name: "total_cost_after_discount" },
          { name: "paid_amount" },
          { name: "remaining_amount" },
          { name: "is_completed" },
        ]}
        summary={true}
        onClickSendToEmail={handleSubmit}
        funDelete={deleteItem}
        edit="transactions"
        name="transactions"
        showTransactions="true"
      />

      <Back />
    </div>
  );
}
export default StudentTransactions;
