import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { fileUrl, useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Row } from "../../Tools/Grid-system";
import { Back, ButtonRed, Input, Selected, Title } from "../../components";
import Loading from "../../Tools/Loading";
import AddImage from "../../Tools/AddFile";
const AddStudents = () => {
  const { id } = useParams();
  const [deleteImgs, setDeleteImgs] = useState(false);
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    handleChangeArrayImages,
    handleCheckedArray,
    setImages,
    setViewImages,
    viewImages,
    images,
    formData,
    loading,
  } = usePOST({});
  const { data } = useFETCH(id === "add" ? "" : `admin/students/${id}`);
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(id === "add" ? "admin/students" : `admin/students/${id}`);
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    id !== "add" &&
      setFormData({
        name: dataOld?.name,
        phone_number: dataOld?.phone_number,
        email: dataOld?.email,
        degree: dataOld?.degree,
        sitting_number: dataOld?.sitting_number || "",
        status: dataOld?.status || "",
        can_manage_setting_number: dataOld?.can_manage_setting_number,
      });
  }, [dataOld]);
  return (
    <>
      {id !== "add" ? (
        <Title title="تعديل الطالب" className="mt-5" />
      ) : (
        <Title title="إضافة طالب" className="mt-5" />
      )}
      <Row className="px-2 space-x-1 justify-center">
        <Col
          md={8}
          className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto"
        >
          <Input
            name="name"
            value={formData?.name}
            onChange={handleChangeInput}
            title="اسم الطالب"
          />
          <Input
            name="phone_number"
            value={formData?.phone_number}
            onChange={handleChangeInput}
            title="رقم الهاتف"
          />
          <Input
            name="email"
            value={formData?.email}
            onChange={handleChangeInput}
            title="الإيميل"
          />
          <Input
            name="degree"
            value={formData?.degree}
            onChange={handleChangeInput}
            title="الدرجة العلمية"
          />
          <Input
            name="sitting_number"
            value={formData?.sitting_number}
            onChange={handleChangeInput}
            title="رقم الجلوس"
          />
          <Selected
            text="الحالة"
            title="اختر الحالة"
            name="status"
            value={formData?.status}
            onChange={handleChangeInput}
          >
            <option value="يدرس">يدرس</option>
            <option value="متخرج">متخرج</option>
            <option value="متوقف حالياً ">متوقف حالياً </option>
            <option value="منسحب">منسحب</option>
          </Selected>

          <div className="my-5" />

          <AddImage
            title="مستندات رسمية"
            type="IMAGES"
            id="images"
            name={"official_documents[]"}
            onChange={handleChangeArrayImages}
          />
          <Row className="py-4">
            {viewImages["official_documents[]"] &&
              viewImages["official_documents[]"]?.map((e, i) => (
                <Col key={i} md={3} xs={4} sm={6} lg={24}>
                  <div className="relative">
                    <span
                      onClick={() => {
                        let total = [...viewImages["official_documents[]"]];
                        let total2 = [...images["official_documents[]"]];
                        total.splice(i, 1);
                        total2.splice(i, 1);
                        setViewImages({
                          ...viewImages,
                          "official_documents[]":
                            total.length === 0 ? [] : total,
                        });
                        setImages({
                          ...images,
                          "official_documents[]":
                            total2.length === 0 ? [] : total2,
                        });
                      }}
                      className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                    >
                      X
                    </span>
                    <img
                      src={e}
                      alt=""
                      className="w-[140px] h-[140px] rounded-2xl"
                    />
                  </div>
                </Col>
              ))}
          </Row>
          {id !== "add" && dataOld?.official_documents.length !== 0 && (
            <div>
              <div
                onClick={() => setDeleteImgs(!deleteImgs)}
                className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg  text-white font-semibold bg-Main my-2 ml-auto "
              >
                حذف الصور
              </div>
              <Row>
                {dataOld?.official_documents?.map((e) => (
                  <Col key={e.id} md={4} sm={6} className="relative">
                    {deleteImgs && (
                      <input
                        type="checkbox"
                        value={e.id}
                        name="official_docs_to_be_deleted_ids[]"
                        onChange={handleCheckedArray}
                        className="absolute -top-2 left-1 h-5 w-5"
                      />
                    )}
                    <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                      <img
                        src={fileUrl + e.file}
                        alt=""
                        className="w-full h-full object-contain rounded-2xl"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          <AddImage
            title="شهادات"
            type="IMAGES"
            id="images2"
            name="certificates[]"
            onChange={handleChangeArrayImages}
          />
          <Row className="py-4">
            {viewImages["certificates[]"] &&
              viewImages["certificates[]"]?.map((e, i) => (
                <Col key={i} md={3} xs={4} sm={6} lg={24}>
                  <div className="relative">
                    <span
                      onClick={() => {
                        let total = [...viewImages["certificates[]"]];
                        let total2 = [...images["certificates[]"]];
                        total.splice(i, 1);
                        total2.splice(i, 1);
                        setViewImages({
                          ...viewImages,
                          "certificates[]": total.length === 0 ? [] : total,
                        });
                        setImages({
                          ...images,
                          "certificates[]": total2.length === 0 ? [] : total2,
                        });
                      }}
                      className="bg-red-700 w-6 h-6 flex justify-center items-center font-bold text-white cursor-pointer z-10 -top-2 rounded-full absolute"
                    >
                      X
                    </span>
                    <img
                      src={e}
                      alt=""
                      className="w-[140px] h-[140px] rounded-2xl"
                    />
                  </div>
                </Col>
              ))}
          </Row>
          {id !== "add" && dataOld?.certificates.length !== 0 && (
            <div>
              <div
                onClick={() => setDeleteImgs(!deleteImgs)}
                className="cursor-pointer px-5 py-2 border border-Main w-fit rounded-lg  text-white font-semibold bg-Main my-2 ml-auto "
              >
                حذف الصور
              </div>
              <Row>
                {dataOld?.certificates?.map((e) => (
                  <Col key={e.id} md={4} sm={6} className="relative">
                    {deleteImgs && (
                      <input
                        type="checkbox"
                        value={e.id}
                        name="certificates_to_be_deleted_ids[]"
                        onChange={handleCheckedArray}
                        className="absolute -top-2 left-1 h-5 w-5"
                      />
                    )}
                    <div className="w-full h-[200px] bg-slate-100 rounded-md p-2">
                      <img
                        src={fileUrl + e.file}
                        alt=""
                        className="w-full h-full object-contain rounded-2xl"
                      />
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}
          {loading ? <Loading /> : ""}

          <ButtonRed
            name={id !== "add" ? "تعديل" : "اضافة"}
            onClick={handleSubmitMain}
            className="py-3 mt-3"
          />
        </Col>
      </Row>
      <Back />
    </>
  );
};

export default AddStudents;
