import { useState } from "react";
import { usePOST } from "../../Tools/APIs";
import { FiUploadCloud } from "react-icons/fi";
import { Tooltip } from "react-tooltip";
import Loading from "../../Tools/Loading";
const GivingAll = ({ id }) => {
  const [sure, setSure] = useState(false);
  const { handleSubmit, loading } = usePOST({});
  const handleClick = () => {
    handleSubmit(
      `admin/users/${id}/balance/logs/manage/status?status=COMPLETED`,
      "",

      true
    );
  };
  return (
    <>
      {" "}
      {loading ? <Loading /> : ""}
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[160px]">
              <p className="font-semibold text-xl text-center py-3 ">
                هل تريد تسليم كافة السجلات
              </p>
              <div className="flex items-end justify-center m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={handleClick}
                  >
                    نعم
                  </div>
                  <div
                    className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={() => setSure(false)}
                  >
                    تراجع
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div onClick={() => setSure(true)}>
        <div id="my-anchor-element-1">
          <FiUploadCloud className="text-gray-500" size={30} />
          <Tooltip
            anchorSelect="#my-anchor-element-1"
            content="تسليم كافة السجلات"
          />
        </div>
      </div>
    </>
  );
};

export default GivingAll;
