import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { fileUrl, useFETCH, useFilter, usePOST } from "../../Tools/APIs";
import AddImage from "../../Tools/AddFile";
import { Col, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";
import {
  Back,
  ButtonRed,
  Input,
  Selected,
  TextArea,
  Title,
} from "../../components";
import { DataTransaction } from "./Payment";

const AddPayment = () => {
  const { searchParams } = useFilter();
  const { id, idd } = useParams();
  let today = new Date();
  let dates = today.toISOString().substring(0, 10);
  const {
    handleChangeInput,
    handleSubmit,
    setFormData,
    setViewFile,
    viewFile,
    formData,
    loading,
  } = usePOST({ date: dates });
  const { data: dataAdmins } = useFETCH(
    `admin/users?role=beneficiary&paginate=0`
  );
  const { data: dataPayment } = useFETCH(`admin/payment/methods?paginate=0`);
  const { data } = useFETCH(
    idd === "add" ? "" : `admin/transactions/${id}/payments/${idd}`
  );
  const handleSubmitMain = (e) => {
    e.preventDefault();
    handleSubmit(
      idd === "add"
        ? `admin/transactions/${id}/payments`
        : `admin/transactions/${id}/payments/${idd}`
    );
  };
  let dataOld = data?.data.data;
  useEffect(() => {
    idd !== "add" &&
      setFormData({
        date: dataOld?.date || "",
        amount: dataOld?.amount || "",
        state: dataOld?.state || "",
        payment_method_id: dataOld?.payment_method?.id || "",
        transferred_by: dataOld?.transferred_by || "",
        transfer_number: dataOld?.transfer_number || "",
        transfer_date: dataOld?.transfer_date || "",
        transfer_arrival_date: dataOld?.transfer_arrival_date || "",
        transfer_amount: dataOld?.transfer_amount || "",
        transfer_recipient_id: dataOld?.transfer_recipient_id || "",
        received_amount: dataOld?.received_amount || "",
        notes: dataOld?.notes || "",
        transfer_recipient_commission:
          dataOld?.transfer_recipient_commission || "",
      });
  }, [dataOld]);
  return (
    <>
      {idd !== "add" ? (
        <Title title="تعديل الدفعة" className="mt-5" />
      ) : (
        <Title title="انشاء دفعة" className="mt-5" />
      )}
      <DataTransaction id={id} />
      <Col sm={14} className="mx-auto">
        <Row className="bg-white border-2 border-Main rounded-2xl p-3 mx-auto justify-center">
          <Col md={7}>
            <Input
              type="date"
              title="تاريخ الدفعة"
              name="date"
              value={formData?.date ? formData.date : dates}
              onChange={handleChangeInput}
            />
          </Col>
          <Col md={7}>
            <Input
              type="number"
              title="قيمة الدفعة"
              name="amount"
              value={formData?.amount}
              onChange={handleChangeInput}
            />
          </Col>
          <Col md={7}>
            <Selected
              text="طريقة الدفع"
              title="اختر طريقة الدفع"
              name="payment_method_id"
              value={formData?.payment_method_id}
              onChange={handleChangeInput}
            >
              {dataPayment?.data.data.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Selected>
          </Col>

          <div className="border-t-2 border-Secondary !my-8 !w-[60vw]" />
          <h1 className="text-2xl font-bold text-center my-7 underline decoration-orange-500">
            معلومات الحوالة
          </h1>
          <Col md={7}>
            <Input
              title="اسم الشخص الذي حوّل الحوالة"
              name="transferred_by"
              value={formData?.transferred_by}
              onChange={handleChangeInput}
            />
          </Col>
          <Col md={7}>
            <Input
              type="number"
              title="رقم الحوالة"
              name="transfer_number"
              value={formData?.transfer_number}
              onChange={handleChangeInput}
            />
          </Col>
          <Col md={7}>
            <Input
              type="date"
              title="تاريخ الحوالة"
              name="transfer_date"
              value={formData?.transfer_date}
              onChange={handleChangeInput}
            />
          </Col>
          <Col
            md={7}
            className={`${
              sessionStorage.getItem("role_name") ===
                "financial_collection_officer" ||
              sessionStorage.getItem("role_name") === "register_manager"
                ? "hidden"
                : ""
            }`}
          >
            <Input
              type="date"
              title="تاريخ وصول الحوالة (اذا كانت لحساب بنكي)"
              name="transfer_arrival_date"
              value={formData?.transfer_arrival_date}
              onChange={handleChangeInput}
            />
          </Col>
          <Col md={7}>
            <div className="mb-4" />
            <Selected
              name="transfer_recipient_id"
              value={formData?.transfer_recipient_id}
              onChange={handleChangeInput}
              text="اسم مستلم الحوالة "
              title="اختر اسم مستلم الحوالة "
            >
              {dataAdmins?.data.data?.map((e) => (
                <option key={e.id} value={e.id}>
                  {e.name}
                </option>
              ))}
            </Selected>
          </Col>
          {sessionStorage.getItem("role_name") === "financial_manager" ||
          sessionStorage.getItem("role_name") === "super_admin" ? (
            <Col md={7}>
              <Input
                type="number"
                title="عمولة مستلم الحوالة"
                name="transfer_recipient_commission"
                value={formData?.transfer_recipient_commission}
                onChange={handleChangeInput}
              />
            </Col>
          ) : (
            ""
          )}
          <Col md={7}>
            <Input
              type="number"
              title="قيمة الحوالة"
              name="transfer_amount"
              value={formData?.transfer_amount}
              onChange={handleChangeInput}
            />
          </Col>
          <Col
            md={7}
            className={`${
              sessionStorage.getItem("role_name") ===
                "financial_collection_officer" ||
              sessionStorage.getItem("role_name") === "register_manager"
                ? "hidden"
                : ""
            }`}
          >
            <Input
              type="number"
              title="المبلغ الذي وصل"
              name="received_amount"
              value={formData?.received_amount}
              onChange={handleChangeInput}
            />
          </Col>
          <Col sm={14}>
            <TextArea
              title="ملاحظات"
              name="notes"
              value={formData?.notes}
              onChange={handleChangeInput}
            />
          </Col>
          <Col md={7} className="">
            {sessionStorage.getItem("role_name") === "register_manager" ||
            sessionStorage.getItem("role_name") ===
              "financial_collection_officer" ? (
              ""
            ) : (
              <Selected
                name="state"
                value={formData?.state}
                onChange={handleChangeInput}
                text="حالة الدفعة"
                title="اختر حالة الدفعة"
                optionValue="true"
              >
                <option value="2">مقبولة</option>
              </Selected>
            )}
          </Col>
          <Col md={7}>
            <div className="">
              <h1>أضف صورة</h1>
              <AddImage
                type="file"
                name="transfer_image"
                newImage={viewFile}
                oldImage={
                  dataOld?.transfer_image
                    ? fileUrl + dataOld?.transfer_image
                    : ""
                }
                onChange={handleChangeInput}
                title="إضف صورة"
                clickDeleteImage={() => {
                  setViewFile("");
                  setFormData({ ...formData, transfer_image: "" });
                }}
              />
            </div>
          </Col>
          <Row>
            <Col md={7} className="mx-auto">
              {loading ? <Loading /> : ""}
              <ButtonRed
                name={idd !== "add" ? "تعديل" : "اضافة"}
                onClick={handleSubmitMain}
                className="py-3 mt-3"
              />
            </Col>
          </Row>
        </Row>
      </Col>
      <Back />
    </>
  );
};

export default AddPayment;
