import ButtonRed from "../Buttons/ButtonRed";
import { useContextHook } from "../../Context/ContextOPen";

function TotalBalance({
  onClick,
  name,
  successfulResponse,
  setSuccessfulResponse,
}) {
  const { setMessage } = useContextHook();

  return (
    <div>
      {successfulResponse && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
            onClick={() => setSuccessfulResponse(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
              <p className="font-semibold text-xl text-center py-7">
                هل أنت متأكد من تسليم هذا المبلغ {name}
              </p>
              <div className="flex items-center justify-center m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={onClick}
                  >
                    نعم
                  </div>
                </div>

                <button
                  onClick={() => setSuccessfulResponse(false)}
                  className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  لا
                </button>
              </div>
            </div>
            نعم
          </div>
        </>
      )}
      <div>
        <ButtonRed
          onClick={() => {
            if (name) {
              setSuccessfulResponse(true);
            } else {
              setMessage(["لا يمكن تسليم ،المبلغ يساوي الصفر "]);
            }
          }}
          className=""
          name={`هل تريد تسليم المبلغ ${name}`}
        />
      </div>
    </div>
  );
}
export default TotalBalance;
