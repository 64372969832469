import { useContextHook } from "../../Context/ContextOPen";
import "./SideBar.css";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo.png";
import Beneficiaries from "../../svg/pay_11112919.svg";
import students from "../../svg/leadership_8914016 (1).svg";
import transactions from "../../svg/sort_12963813.svg";
import processes from "../../svg/money-bag_9539294 (2).svg";
import profits from "../../svg/bar-graph_6210230.svg";
import payments from "../../svg/payment-method_1849263.svg";
import specialties from "../../svg/qualification_2727697.svg";
import items from "../../svg/chart-tree-map_9798121.svg";
import academic from "../../svg/diploma_10306883.svg";
import admins from "../../svg/teamwork_584536.svg";
import wallet from "../../svg/wallet_765047.svg";
import user from "../../svg/user_1144709.svg";
import { RiLockPasswordLine } from "react-icons/ri";
function SideBar() {
  const { openMenu, changeMenu } = useContextHook();
  const sidebar = [
    { name: " كادر الأكاديمية", link: "/admins", image: admins },
    { name: "الطلاب", link: "/students", image: students },
    { name: " الملفات المالية", link: "/transactions", image: transactions },
    { name: "المصاريف", link: "/processes", image: processes },
    { name: "الأصناف", link: "/items", image: items },
    { name: "المالية", link: "/profits", image: profits },
    {
      name: "معلوماتي المالية",
      link: `/my-info/all`,
      image: wallet,
    },
    { name: "المستفيدين", link: "/beneficiaries", image: Beneficiaries },
    { name: "الكليات", link: "/specialties", image: specialties },
    { name: "الدرجات العلمية", link: "/academic-degrees", image: academic },
    { name: "طرق الدفع", link: "/payments", image: payments },
    { name: "بروفايل", link: "/profile", image: user },
    { name: "تغيير كلمة المرور", link: "/Change-Password", image: "" },
  ];
  const sidebarTwo = [
    { name: "الطلاب", link: "/students", image: students },
    { name: " الملفات المالية", link: "/transactions", image: transactions },
    {
      name: "معلوماتي المالية",
      link: `/my-info/all`,
      image: wallet,
    },
    { name: "بروفايل", link: "/profile", image: user },
    { name: "تغيير كلمة المرور", link: "/Change-Password", image: "" },
  ];
  const sidebarThree = [
    { name: " الملفات المالية", link: "/transactions", image: transactions },
    {
      name: "معلوماتي المالية",
      link: `/my-info/all`,
      image: wallet,
    },
    { name: "بروفايل", link: "/profile", image: user },
    { name: "تغيير كلمة المرور", link: "/Change-Password", image: "" },
  ];
  return (
    <>
      <div
        className={`${
          openMenu
            ? "!w-[300px] max-lg:!w-[250px] max-sm:!w-[250px]"
            : " w-[0px]"
        } ${
          openMenu
            ? "!max-md:translate-x-0 !max-md:w-3/4 -right-0"
            : "!max-md:-translate-x-full"
        } ml-2 bg-Main sidebar h-[100vh] max-md:h-[100%] !z-30 sticky max-md:fixed right-0 top-0 transition-all overflow-y-auto  py-10`}
      >
        <div className="px-3">
          <ul className="text-black text-center font-semibold space-y-4">
            <li className="max-md:pt-20">
              <img
                src={logo}
                alt=""
                className="w-[170px] px-2 py-5 mx-auto bg-white rounded-xl  mt-1"
              />
            </li>
            {sessionStorage.getItem("role_name") ===
              "financial_collection_officer" &&
              sidebarThree.map((e, i) => (
                <li key={i} className="">
                  <NavLink
                    to={e.link}
                    className="flex items-center gap-3 text-sm py-2 rounded-3xl hover:bg-Third text-white"
                  >
                    {e.image ? (
                      <img src={e.image} className="w-6 mx-5" alt="" />
                    ) : (
                      <RiLockPasswordLine
                        size={25}
                        className="mx-5 text-Fourth"
                      />
                    )}
                    {e.name}
                  </NavLink>
                </li>
              ))}
            {sessionStorage.getItem("role_name") === "register_manager"
              ? sidebarTwo.map((e, i) => (
                  <li
                    key={i}
                    className={`${
                      sessionStorage.getItem("role_name") ===
                      "financial_collection_officer"
                        ? "hidden"
                        : ""
                    }`}
                  >
                    <NavLink
                      to={e.link}
                      className="flex items-center gap-3 text-sm py-2 rounded-3xl hover:bg-Third text-white"
                    >
                      {e.image ? (
                        <img src={e.image} className="w-6 mx-5" alt="" />
                      ) : (
                        <RiLockPasswordLine
                          size={25}
                          className="mx-5 text-[#FE9923]"
                        />
                      )}
                      {e.name}
                    </NavLink>
                  </li>
                ))
              : sidebar.map((e, i) => (
                  <li
                    key={i}
                    className={`${
                      sessionStorage.getItem("role_name") ===
                      "financial_collection_officer"
                        ? "hidden"
                        : ""
                    }`}
                  >
                    <NavLink
                      to={e.link}
                      className="flex items-center gap-3 text-sm py-2 rounded-3xl hover:bg-Third text-white"
                    >
                      {e.image ? (
                        <img
                          src={e.image}
                          className={i === 9 ? "w-8 mx-5" : "w-6 mx-5"}
                          alt=""
                        />
                      ) : (
                        <RiLockPasswordLine
                          size={25}
                          className="mx-5 text-[#FE9923]"
                        />
                      )}
                      {e.name}
                    </NavLink>
                  </li>
                ))}
          </ul>
          
        </div>
      </div>
      <div
        onClick={changeMenu}
        style={{ transition: "0.4s" }}
        className={`${
          openMenu ? "" : "hidden"
        }  max-md:w-full h-full bg-black/20 fixed -left-0 top-0 transition-all z-[25]`}
      />
    </>
  );
}

export default SideBar;
