import { HiFilter } from "react-icons/hi";
import { Col, Container, Row } from "../../Tools/Grid-system";
import {
  Add,
  ArchiveFilter,
  Back,
  DeleteParamsAll,
  Filter,
  Search,
  Selected,
  Table,
  Title,
} from "../../components";
import { useClose, useFETCH, useFilter, usePOST } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import SingleButFiler from "../../components/Filter/SingleButFiler";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useContextHook } from "../../Context/ContextOPen";
function Transactions() {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  const { search } = useLocation();
  const [specialtySectionId, setSpecialtySectionId] = useState(1);
  const { handleSubmit, dataPlayer, loading } = usePOST({});
  const { open, setOpen, mouse } = useClose();
  const { data: dataSpecialty } = useFETCH(
    `admin/specialty-sections?paginate=0`
  );
  const { data: specialties } = useFETCH(
    `admin/specialty-sections/${specialtySectionId}/specialties?paginate=0`
  );
  const { data, deleteItem, isLoading } = useFETCH(
    `admin/transactions${search}`,
    `admin/transactions`
  );
  const { setSuccessMessage } = useContextHook();
  useEffect(() => {
    if (dataPlayer?.data?.data.success) {
      setSuccessMessage(["تم ارسال التذكير الى ايميل الطالب"]);
    }
  }, [dataPlayer?.data.data.success]);
  useEffect(() => {
    handleParamsClick("in_archive", 0);
  }, []);
  return (
    <div>
      <Container>
        <Title title=" الملفات المالية" />
        <Row className="mb-5">
          <Col xs={4}>
            <Search placeholder="اسم الطالب" />
          </Col>
          <Col xs={4}>
            <div ref={mouse} className="relative">
              <button
                className={` w-full bg-Third rounded-xl flex justify-center gap-4 items-center py-3 px-5 text-white`}
                onClick={() => setOpen(!open)}
              >
                فرز
                <HiFilter size={20} />
              </button>
              <div
                className={`${
                  open
                    ? "absolute top-12 transition-all max-sm:right-1 right-7 bg-Third rounded-md text-center space-y-2 py-2 px-5 text-white mt-1"
                    : "hidden"
                }`}
              >
                <Filter
                  onclick={() => handleParamsDelete("date")}
                  text="التاريخ"
                >
                  <input
                    type="date"
                    placeholder="التارخ"
                    value={searchParams.get("date")}
                    onChange={(e) => handleParamsClick("date", e.target.value)}
                    className="text-black p-2 rounded-xl w-40 border-2 border-Secondary"
                  />
                </Filter>
                <Filter
                  onclick={() => handleParamsDelete("specialty_id")}
                  className="w-[300px] max-sm:w-[170px] !h-[200px]"
                  text="حسب التخصص"
                >
                  <Selected
                    className="!text-Third"
                    text="الكليات"
                    title="اختر الكلية"
                    value={setSpecialtySectionId}
                    onChange={(e) => {
                      setSpecialtySectionId(e.target.value);
                      if (searchParams.has("specialty_id")) {
                        handleParamsClick("specialty_id", "");
                      }
                    }}
                  >
                    {dataSpecialty?.data.data.map((e, i) => (
                      <option className="!text-Third" key={e.id} value={e.id}>
                        {e.name}
                      </option>
                    ))}
                  </Selected>
                  <div className="my-3">
                    <Selected
                      className="!text-Third"
                      text="التخصصات"
                      title="اختر التخصص"
                      value={searchParams.get("specialty_id")}
                      onChange={(e) =>
                        handleParamsClick("specialty_id", e.target.value)
                      }
                    >
                      {specialties?.data.data?.map((e) => (
                        <option key={e.id} value={e.id}>
                          {e.name}
                        </option>
                      ))}
                    </Selected>
                  </div>
                </Filter>
              </div>
            </div>
          </Col>

          <Col md={6} lg={5}>
            <ArchiveFilter />
          </Col>
        </Row>
        <Row className="mb-5">
          <DeleteParamsAll />
          <SingleButFiler value="1" name="completed" title="مكتملة" />
          <SingleButFiler value="0" name="completed" title="غير مكتملة" />
          <SingleButFiler
            value="1"
            name="failed_to_pay"
            title="تم التأخير عن الدفع "
          />
        </Row>
        {isLoading || loading ? <Loading /> : ""}
      </Container>
      <Table
        thead={[
          "تاريخ ",
          " الطالب",
          "التخصص",
          "قيمة ",
          "القيمة  بعد الخصم",
          "المبلغ  المدفوع",
          "المبلغ  المتبقي",
          "الحالة ",
        ]}
        pageCount={Math.ceil(data?.data.data.total / data?.data.data.per_page)}
        tData={data?.data.data.data}
        tbody={[
          { name: "date" },
          { name: "student", object: "name" },
          { name: "specialty", object: "name" },
          { name: "total_cost" },
          { name: "total_cost_after_discount" },
          { name: "paid_amount" },
          { name: "remaining_amount" },
          { name: "is_completed" },
        ]}
        funDelete={deleteItem}
        summary={true}
        onClickSendToEmail={handleSubmit}
        edit="transactions"
        name="transactions"
        showTransactions="true"
      />
      {/* {sessionStorage.getItem("role_name") ===
      "financial_collection_officer" ? (
        ""
      ) : (
        <Add link={`/transactions/add`} />
      )} */}

      <Back />
    </div>
  );
}
export default Transactions;
