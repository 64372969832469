import { CiSearch } from "react-icons/ci";
import { useClose, useFilter } from "../../Tools/APIs";
import Filter from "./Filter";

const DateFilter = ({ now }) => {
  const { open, setOpen, mouse } = useClose();
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  const dey = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${(new Date().getDate() - 1)
    .toString()
    .padStart(2, "0")}`;
  const week = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${(new Date().getDate() - 7)
    .toString()
    .padStart(2, "0")}`;
  const month = `${new Date().getFullYear()}-${new Date()
    .getMonth()
    .toString()
    .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`;

  return (
    <>
      <div ref={mouse} className="relative">
        <div
          className={`w-full bg-Third rounded-xl flex justify-center gap-4 items-center py-3 px-5 text-white`}
          onClick={() => setOpen(!open)}
        >
          بحث حسب التاريخ
          <CiSearch size={20} />
        </div>

        <div
          className={`${
            open
              ? "absolute top-12 transition-all right-2 bg-Third rounded-md text-center text-white  mt-1"
              : "hidden"
          }`}
        >
          <Filter
            onclick={() => {
              handleParamsDelete("start_date");
              handleParamsDelete("end_date");
            }}
            text="الكل"
          ></Filter>
          <Filter
            classNameBut={
              searchParams.get("start_date") === dey ? "bg-Main" : ""
            }
            onclick={() => {
              handleParamsClick("start_date", dey);
              handleParamsDelete("end_date");
            }}
            text="اليوم"
          />
          <Filter
            classNameBut={
              searchParams.get("start_date") === week ? "bg-Main" : ""
            }
            onclick={() => {
              handleParamsClick("start_date", week);
              handleParamsDelete("end_date");
            }}
            text="الاسبوع"
          />
          <Filter
            classNameBut={
              searchParams.get("start_date") === month ? "bg-Main" : ""
            }
            onclick={() => {
              handleParamsClick("start_date", month);
              handleParamsDelete("end_date");
            }}
            text="الشهر"
          />
          <Filter
            classNameBut={searchParams.get("end_date") ? "bg-Main" : ""}
            onclick={() => console.log()}
            text="تاريخ محدد"
          >
            <div
              md={5}
              className={`flex justify-end items-center flex-col gap-5 py-2 `}
            >
              <div className="border border-white p-2 rounded-xl relative flex items-center justify-center ">
                <span className="absolute bg-Third -top-3 px-2 left-3">
                  تاريخ البداية
                </span>
                <input
                  type="date"
                  className="bg-transparent text-white"
                  value={searchParams.get("start_date")}
                  onChange={(e) => {
                    handleParamsClick("start_date", e.target.value);
                  }}
                />
              </div>
              <div className="border border-white p-2 rounded-xl relative flex items-center justify-center">
                <span className="absolute -top-3 bg-Third px-2 left-3">
                  تاريخ النهاية
                </span>
                <input
                  className="bg-transparent text-white"
                  type="date"
                  value={searchParams.get("end_date")}
                  onChange={(e) =>
                    handleParamsClick("end_date", e.target.value)
                  }
                />
              </div>
            </div>
          </Filter>
        </div>
      </div>
    </>
  );
};

export default DateFilter;
