import { Col, Container, Row } from "../../Tools/Grid-system";
import { Add, Back, Filter, Search, Table, Title } from "../../components";
import { CiSearch } from "react-icons/ci";
import { useClose, useFETCH, useFilter } from "../../Tools/APIs";
import Loading from "../../Tools/Loading";
import StudentFilter from "../../components/Filter/StudentFilter";
import { useLocation } from "react-router-dom";
function Students() {
  const { search } = useLocation();
  const { open, setOpen, mouse } = useClose();
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/students${search}`,
    `admin/students`
  );

  return (
    <>
      <Container>
        {isLoading ? <Loading /> : ""}
        <Title title="الطلاب" />
        <Row>
          <Col md={3} xs={6}>
            <div ref={mouse} className="relative">
              <div
                className={`w-full bg-Third rounded-xl flex justify-center gap-4 items-center py-3 px-5 text-white`}
                onClick={() => setOpen(!open)}
              >
                بحث حسب
                <CiSearch size={20} />
              </div>
              <div
                className={`${
                  open
                    ? "absolute top-12 transition-all right-2 bg-Third rounded-md text-center text-white  mt-1"
                    : "hidden"
                }`}
              >
                <Filter onclick={() => console.log()} text="الاسم">
                  <Search className="w-[200px] text-black" name="name" />
                </Filter>
                <Filter onclick={() => console.log()} text="رقم الهاتف">
                  <Search
                    className="w-[200px] text-black"
                    name="phone_number"
                  />
                </Filter>
                <Filter onclick={() => console.log()} text="رقم الجلوس">
                  <Search
                    className="w-[200px] text-black"
                    name="sitting_number"
                  />
                </Filter>
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <StudentFilter />
        </Row>
      </Container>
      <Table
        thead={[
          "اسم الطالب",
          "رقم الهاتف",
          "الايميل",
          "رقم الجلوس",
          "الدرجة العلمية",
          "الحالة",
        ]}
        pageCount={Math.ceil(
          data?.data.data?.total / data?.data.data?.per_page
        )}
        tData={data?.data?.data?.data}
        tbody={[
          { name: "name" },
          { name: "phone_number" },
          { name: "email" },
          { name: "sitting_number" },
          { name: "degree" },
          { name: "status" },
        ]}
        funDelete={deleteItem}
        edit="students"
        shows="students/info"
        showProStudent="true"
      />
      <Add link={`/students/add`} />
      <Back />
    </>
  );
}
export default Students;
