import { useEffect, useState } from "react";
import { MdArchive } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { usePOST } from "../../Tools/APIs";
import { useLocation } from "react-router-dom";
import Loading from "../../Tools/Loading";
const Download = ({ iddd, name, showTransactions }) => {
  const [sure, setSure] = useState(false);
  const { handleSubmit, loading, dataPlayer } = usePOST({});
  const { pathname } = useLocation();
  const handleClick = () => {
    handleSubmit(`admin/${name}/${iddd}/manage/archive?in_archive=1`, "", true);
  };
  useEffect(() => {
    if (dataPlayer?.data.code) {
      setSure(false);
    }
  }, [dataPlayer?.data.code]);
  return (
    <>
      {loading ? <Loading /> : ""}
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
              <MdArchive size={70} className="mx-auto text-blue-700" />
              <p className="font-semibold text-xl text-center py-3 ">
                {!showTransactions
                  ? "هل تريد أرشفة المصروف"
                  : " هل تريد أرشفة الملف المالي "}
              </p>
              <div className="flex items-end justify-center m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={handleClick}
                  >
                    نعم
                  </div>
                  <div
                    className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={() => setSure(false)}
                  >
                    تراجع
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div id="my-anchor-element">
          <div
            onClick={() => setSure(true)}
            className="w-10 h-10 text-blue-700 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center"
          >
            <MdArchive size={30} className="" />
          </div>
          <Tooltip
            anchorSelect="#my-anchor-element"
            content={
              pathname === "/transactions"
                ? "أرشفة الملف المالي "
                : "أرشفة المصروف"
            }
          />
        </div>
      </div>
    </>
  );
};

export default Download;
