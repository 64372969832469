import { useEffect, useState } from "react";
import { BsWalletFill } from "react-icons/bs";
import { CiSearch } from "react-icons/ci";
import { FaEye } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { useClose, useFETCH, usePOST } from "../../Tools/APIs";
import { Col, Container, Row } from "../../Tools/Grid-system";
import Loading from "../../Tools/Loading";

import {
  Add,
  AdminsFilter,
  Back,
  ButtonRed,
  Delete,
  DeleteParamsAll,
  Filter,
  GiveSalaries,
  Search,
  Title,
  TotalBalance,
  Update,
} from "../../components";
import { useContextHook } from "../../Context/ContextOPen";
function Users() {
  const { search } = useLocation();
  const {
    handleSubmit,
    totalBalance,
    loading,
    checkArray,
    setCheckArray,
    successfulResponse,
    setSuccessfulResponse,
  } = usePOST({});
  const { data, isLoading, deleteItem } = useFETCH(
    `admin/users?paginate=0${search ? "&" + search.substring(1) : ""}`,
    `admin/users`
  );
  const [selectApi, setSelectApi] = useState("");
  const { setMessage } = useContextHook();
  useEffect(() => {
    setCheckArray((prevState) => ({
      "users_ids[]": [],
    }));
  }, []);

  return (
    <Container>
      <Title title=" كادر الأكاديمية" />
      {loading || isLoading ? <Loading /> : ""}
      <FilterUser />
      <Row justify="center md:px-3">
        <Col className="overflow-x-auto ">
          <table className="w-full   max-md:w-[700px] mx-auto my-5 text-center rounded-2xl overflow-hidden border border-Secondary">
            <thead className="bg-Main text-white h-[70px]">
              <tr>
                <td className="px-7">رقم</td>
                <td className="px-7">الاسم</td>
                <td className="px-7">الايميل</td>
                <td className="px-7">رقم الهاتف</td>
                <td className="px-7">نوع الحساب </td>
                <td className="px-7">الراتب</td>
                <td className="px-7">الرصيد</td>
                <td className="px-7">العمليات</td>
              </tr>
            </thead>
            <tbody className="">
              {isLoading ? (
                <Loading />
              ) : (
                data?.data.data.map((e, i) => (
                  <tr key={i} className="border border-Main bg-white">
                    <td className="">{e.id}</td>
                    <td className="">{e.name}</td>
                    <td className="">{e.email}</td>
                    <td className="">{e.phone_number}</td>
                    <td className="">{e.role?.display}</td>
                    <td className="">{e.salary}</td>
                    <td className="">{e.balance}</td>
                    <td className="flex justify-center items-center gap-3 mt-2">
                      <div className="flex justify-center items-center gap-3">
                        {selectApi && (
                          <div className="flex items-center gap-3 my-4  font-semibold">
                            <div
                              onClick={() => {
                                if (
                                  !checkArray?.["users_ids[]"].includes(e.id)
                                ) {
                                  setCheckArray((prevState) => ({
                                    ...prevState,
                                    "users_ids[]": [
                                      ...prevState["users_ids[]"],
                                      e.id,
                                    ],
                                  }));
                                } else {
                                  setCheckArray((prevState) => ({
                                    ...prevState,
                                    "users_ids[]": prevState?.[
                                      "users_ids[]"
                                    ].filter((id) => id !== e.id),
                                  }));
                                }
                              }}
                              className={`w-6 h-6 border border-black rounded-md ${
                                checkArray?.["users_ids[]"]?.includes(e.id)
                                  ? "bg-Main"
                                  : ""
                              }`}
                            />
                          </div>
                        )}
                        <Update name={e.name} edit={`/admins/${e.id}`} />
                        <div id="my-anchor-element-wallet">
                          <div
                            className={` w-10 h-10 rounded-full  flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                          >
                            <Link to={`/admins/wallet/${e.id}`}>
                              <BsWalletFill
                                size={30}
                                className="text-sky-950"
                              />
                            </Link>
                          </div>
                          <Tooltip
                            anchorSelect="#my-anchor-element-wallet"
                            content="المحفظة"
                          />
                        </div>
                        <div id="my-anchor-element-show">
                          <div
                            className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
                          >
                            <Link to={`/admins/info/${e.id}`}>
                              <FaEye size={27} className="text-blue-700" />
                            </Link>
                          </div>
                          <Tooltip
                            anchorSelect="#my-anchor-element-show"
                            content="عرض التفاصيل"
                          />
                        </div>
                        {e.name !== "آدمن رئيسي" ? (
                          <Delete
                            id={e.id}
                            name={e.display || e.name}
                            onClick={() => deleteItem(e)}
                          />
                        ) : (
                          <div className="w-10 h-10">
                            <div className="w-[30px] h-[30px]" />
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
          {/* ================================================================= */}
          <Row justify="">
            <Col md={3} sm={7} xs={4}>
              <ButtonRed
                className={` ${
                  selectApi === "admin/users/check/balances?type=SALARIES"
                    ? "bg-[#FF9600]"
                    : ""
                }`}
                onClick={() => {
                  if (
                    selectApi !== "admin/users/check/balances?type=SALARIES"
                  ) {
                    setSelectApi("admin/users/check/balances?type=SALARIES");
                  } else {
                    setSelectApi("");
                  }
                }}
                name="حساب الرواتب "
              />
            </Col>
            <Col md={3} sm={7} xs={4}>
              <ButtonRed
                className={` ${
                  selectApi === "admin/users/check/balances?type=OTHERS"
                    ? "bg-[#FF9600]"
                    : ""
                }`}
                onClick={() => {
                  if (selectApi !== "admin/users/check/balances?type=OTHERS") {
                    setSelectApi("admin/users/check/balances?type=OTHERS");
                  } else {
                    setSelectApi("");
                  }
                }}
                name="حساب العمولات "
              />
            </Col>
            <Col md={3} sm={7} xs={4}>
              <ButtonRed
                className={` ${
                  selectApi === "admin/users/give/salaries"
                    ? "bg-[#FF9600]"
                    : ""
                }`}
                onClick={() => {
                  if (selectApi !== "admin/users/give/salaries") {
                    setSelectApi("admin/users/give/salaries");
                  } else {
                    setSelectApi("");
                  }
                }}
                name="تنزيل رواتب جديدة"
              />
            </Col>
          </Row>
          {/* ================================================================= */}
          <Row justify="">
            {(selectApi === "admin/users/check/balances?type=SALARIES" ||
              selectApi === "admin/users/check/balances?type=OTHERS") && (
              <>
                <Col md={3} sm={7} xs={4}>
                  <ButtonRed
                    className=""
                    onClick={() => {
                      if (checkArray?.["users_ids[]"]?.length > 0) {
                        handleSubmit(`${selectApi}`, "/admins", "");
                      } else {
                        setMessage(["يجب اختيار أحد العناصر"]);
                      }
                    }}
                    name="احسب"
                  />
                </Col>
                {totalBalance >= 0 && (
                  <Col md={3} sm={7} xs={4}>
                    <TotalBalance
                      setSuccessfulResponse={setSuccessfulResponse}
                      successfulResponse={successfulResponse}
                      name={totalBalance}
                      onClick={() =>
                        handleSubmit(
                          `${selectApi}&transmission=1`,
                          "",
                          "",
                          true
                        )
                      }
                    />
                  </Col>
                )}
              </>
            )}
            {selectApi === "admin/users/give/salaries" && (
              <Col md={3} sm={7} xs={4}>
                <GiveSalaries
                  setSuccessfulResponse={setSuccessfulResponse}
                  successfulResponse={successfulResponse}
                  name={checkArray?.["users_ids[]"]?.length > 0}
                  onClick={() => handleSubmit(selectApi, "", "", true)}
                />
              </Col>
            )}
            {selectApi && (
              <Col md={3} sm={7} xs={4}>
                <div className="flex items-center gap-3 my-4  font-semibold">
                  <div
                    onClick={() => {
                      if (
                        checkArray?.["users_ids[]"]?.length !==
                        data?.data.data.length
                      ) {
                        const allIds = data?.data.data.map((item) => item.id);
                        setCheckArray({
                          "users_ids[]": allIds,
                        });
                      } else {
                        setCheckArray({
                          "users_ids[]": [],
                        });
                      }
                    }}
                    className={`w-6 h-6 border border-black rounded-md bg-white ${
                      checkArray?.["users_ids[]"]?.length ===
                      data?.data.data.length
                        ? "!bg-Main"
                        : ""
                    }`}
                  />
                  <p>تحديد الكل</p>
                </div>
              </Col>
            )}
          </Row>{" "}
          {/* ================================================================= */}
        </Col>
      </Row>

      <Add link={`/admins/add`} />
      <Back />
    </Container>
  );
}
export default Users;
const FilterUser = () => {
  const { open, setOpen, mouse } = useClose();
  return (
    <Row className="px-5">
      <DeleteParamsAll />
      <Col lg={3} md={4} xs={5}>
        <div ref={mouse} className="relative">
          <div
            className={`w-full bg-Third rounded-xl flex justify-center gap-4 items-center py-3 px-5 text-white`}
            onClick={() => setOpen(!open)}
          >
            بحث حسب
            <CiSearch size={20} />
          </div>

          <div
            className={`${
              open
                ? "absolute top-12 transition-all right-2 bg-Third rounded-md text-center text-white  mt-1"
                : "hidden"
            }`}
          >
            <Filter onclick={() => console.log()} text="الاسم">
              <Search className="w-[200px] text-black" name="name" />
            </Filter>
          </div>
        </div>
      </Col>
      <AdminsFilter />
    </Row>
  );
};
