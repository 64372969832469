import React, { useEffect, useRef } from "react";
import ReactPaginate from "react-paginate";
import { useFilter } from "../APIs";
import { Container } from "../Grid-system";

const Pagination = ({ pageCount, children }) => {
  const { handlePageClick, searchParams } = useFilter();
  const a = useRef();
  useEffect(() => {
    if (a.current) {
      a.current.state.selected = searchParams.get("page")
        ? searchParams.get("page") - 1
        : 0;
    }
  }, [searchParams.get("page")]);
  return (
    <>
      <Container>{children}</Container>
      {pageCount < 1 && (
        <p className="text-xl font-semibold text-center">لا يوجد بيانات</p>
      )}
      <div className="flex justify-center my-3">
        <ReactPaginate
          className="pagination flex"
          previousLabel=""
          nextLabel=""
          breakLabel="..."
          ref={a}
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={handlePageClick}
          pageClassName="page-item"
          pageLinkClassName="page-link"
        />
      </div>
    </>
  );
};

export default Pagination;
