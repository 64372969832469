import { useState } from "react";
import { MdUnarchive } from "react-icons/md";
import { Tooltip } from "react-tooltip";
import { usePOST } from "../../Tools/APIs";
import { useLocation } from "react-router-dom";
import Loading from "../../Tools/Loading";
const UpLoad = ({ iddd, name, showTransactions }) => {
  const [sure, setSure] = useState(false);
  const { handleSubmit, loading } = usePOST({});
  const { pathname } = useLocation();
  const handleClick = () => {
    handleSubmit(`admin/${name}/${iddd}/manage/archive?in_archive=0`, "", true);
  };
  return (
    <>
      {loading ? <Loading /> : ""}
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-40 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
              <MdUnarchive size={70} className="mx-auto text-gray-600" />
              <p className="font-semibold text-xl text-center py-3 ">
                {!showTransactions
                  ? " إلغاء أرشفة المصروف"
                  : " إلغاء أرشفة الملف المالي "}
              </p>
              <div className="flex items-end justify-center m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={handleClick}
                  >
                    نعم
                  </div>
                  <div
                    className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={() => setSure(false)}
                  >
                    تراجع
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div id="my-anchor-element-up">
          <div
            onClick={() => setSure(true)}
            className="w-10 h-10 text-gray-600 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center"
          >
            <MdUnarchive size={30} className="" />
          </div>
          <Tooltip
            anchorSelect="#my-anchor-element-up"
            content={
              pathname === "/transactions"
                ? " إلغاء أرشفة الملف المالي "
                : "إلغاء أرشفة المصروف"
            }
          />
        </div>
      </div>
    </>
  );
};

export default UpLoad;
