import React from "react";
import { Col } from "../../Tools/Grid-system";
import { useFilter } from "../../Tools/APIs";

const SingleButFiler = ({ value, name, title }) => {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  return (
    <Col lg={3} md={4}>
      <div
        className={`border-2  border-Third bg-white text-Third rounded-xl hover:bg-opacity-70 text-center font-semibold cursor-pointer w-full px-5 py-[0.6rem]
                    ${
                      searchParams.get(name) === value
                        ? "!bg-Third text-white"
                        : ""
                    }`}
        onClick={() => {
          if (name === "trashed" || name === "failed_to_pay") {
            if (searchParams.get(name) === value) {
              handleParamsDelete(name);
            } else {
              handleParamsClick(name, value);
              handleParamsDelete("completed");
              handleParamsDelete("completed");
            }
          } else {
            if (searchParams.get(name) === value) {
              handleParamsDelete(name);
            } else {
              if (
                searchParams.has("trashed") ||
                searchParams.has("failed_to_pay")
              ) {
                handleParamsClick(name, value);
                handleParamsDelete("trashed");
                handleParamsDelete("failed_to_pay");
              } else {
                handleParamsClick(name, value);
              }
            }
          }
        }}
      >
        {title}
      </div>
    </Col>
  );
};

export default SingleButFiler;
