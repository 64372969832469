import { Navbar, SideBar } from "./layout";
import {
  AcademicDegrees,
  AddAcademicDegrees,
  AddBeneficiaries,
  AddPayment,
  AddPayments,
  AddProcesses,
  AddSingleSpecialties,
  AddSpecialties,
  AddStudents,
  AddTransactions,
  AddUser,
  Beneficiaries,
  BeneficiariesSingleUser,
  BeneficiariesWallet,
  ChangePassword,
  Code,
  EditItems,
  ForgetPassword,
  IframePDF,
  Items,
  Login,
  MyInfo,
  Notifications,
  Payment,
  PaymentDetails,
  Payments,
  Processes,
  Profile,
  Profits,
  SetPassword,
  SingleSpecialties,
  SingleStudents,
  SingleUser,
  Specialties,
  StudentTransactions,
  Students,
  Transactions,
  Users,
  Wallet,
} from "./pages";
import { Route, Routes, useLocation } from "react-router-dom";
import logo from "./images/logo.png";
import { P403, P404, P500 } from "./Tools/Error";
import {
  RequireAdmin,
  RequireAuth,
  RequireLogin,
  useFETCH,
} from "./Tools/APIs";
import { useEffect } from "react";
import { Message, SuccessMessage } from "./components";
const App = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const { data } = useFETCH(
    sessionStorage.getItem("token") ? "admin/check/auth" : ""
  );
  const preventScrollForNumberFields = (e) => {
    if (e.target.type === "number") {
      e.preventDefault();
    }
  };
  useEffect(() => {
    document.addEventListener("wheel", preventScrollForNumberFields, {
      passive: false,
    });
    return () => {
      document.removeEventListener("wheel", preventScrollForNumberFields);
    };
  }, []);
  return (
    <div className="!text-sm ">
      <Message />
      <SuccessMessage />
      <div className="relative flex ">
        <SideBar />
        <div className="container mx-auto relative">
          <Navbar />
          <div className="overflow-hidden min-h-[50vh]">
            <>
              <Routes>
                <Route element={<RequireLogin />}>
                  <Route path="login" element={<Login />} />
                </Route>
                <Route path="forget-password" element={<ForgetPassword />} />
                <Route path="verification-code" element={<Code />} />
                <Route path="set-password" element={<SetPassword />} />

                <Route element={<RequireAuth />}>
                  <Route
                    path=""
                    element={
                      <h1 className="grid place-content-center h-[75vh]">
                        <img
                          src={logo}
                          alt=""
                          className="w-[450px] h-[350px] object-contain max-md:w-[250px] bg- rounded-3xl  "
                        />
                      </h1>
                    }
                  />
                  <Route path="*" element={<P404 />} />
                  <Route path="403" element={<P403 />} />
                  <Route path="500" element={<P500 />} />
                  <Route
                    element={
                      <RequireAdmin name={"financial_collection_officer"} />
                    }
                  >
                    <Route element={<RequireAdmin name={"register_manager"} />}>
                      <Route path="admins">
                        <Route index element={<Users />} />
                        <Route path=":id" element={<AddUser />} />
                        <Route path="info/:id" element={<SingleUser />} />
                        <Route path="wallet/:id" element={<Wallet />} />
                      </Route>
                      <Route path="items">
                        <Route index element={<Items />} />
                        <Route path=":id" element={<EditItems />} />
                        <Route
                          path=":idItems/processes"
                          element={<Processes />}
                        />
                        <Route
                          path=":idItems/processes/:id"
                          element={<AddProcesses />}
                        />
                      </Route>
                      <Route path="processes">
                        <Route index element={<Processes />} />
                        <Route path="wallet/:id" element={<Wallet />} />
                        <Route path=":id" element={<AddProcesses />} />
                      </Route>
                      <Route path="academic-degrees">
                        <Route index element={<AcademicDegrees />} />
                        <Route path=":id" element={<AddAcademicDegrees />} />
                      </Route>
                      <Route path="beneficiaries">
                        <Route index element={<Beneficiaries />} />
                        <Route path=":id" element={<AddBeneficiaries />} />
                        <Route
                          path="info/:id"
                          element={<BeneficiariesSingleUser />}
                        />
                        <Route
                          path="wallet/:id"
                          element={<BeneficiariesWallet />}
                        />
                      </Route>
                      <Route path="specialties">
                        <Route index element={<Specialties />} />
                        <Route path=":id" element={<AddSpecialties />} />
                        <Route
                          path=":id/single-specialties"
                          element={<SingleSpecialties />}
                        />
                        <Route
                          path=":id/single-specialties/:idd"
                          element={<AddSingleSpecialties />}
                        />
                      </Route>
                      <Route path="profits" element={<Profits />} />
                      <Route path="payments">
                        <Route index element={<Payments />} />
                        <Route path=":id" element={<AddPayments />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route
                    element={
                      <RequireAdmin name={"financial_collection_officer"} />
                    }
                  >
                    <Route path="students">
                      <Route index element={<Students />} />
                      <Route
                        path="transactions/:id"
                        element={<StudentTransactions />}
                      />
                      <Route
                        path=":student_id/transactions/:id"
                        element={<AddTransactions />}
                      />
                      <Route path=":id" element={<AddStudents />} />
                      <Route path="info/:id" element={<SingleStudents />} />
                    </Route>
                  </Route>

                  <Route path="transactions">
                    <Route index element={<Transactions />} />
                    <Route
                      path=":id/payment/pdf/:idPayment"
                      element={<IframePDF />}
                    />
                    <Route
                      path=":id/payments/summary/pdf"
                      element={<IframePDF summary={true} />}
                    />
                    <Route path=":id" element={<AddTransactions />} />
                    <Route path=":id/payment" element={<Payment />} />
                    <Route path=":id/payment/:idd" element={<AddPayment />} />
                    <Route
                      path=":id/payment-details/:idd"
                      element={<PaymentDetails />}
                    />
                  </Route>
                  <Route path={`my-info/:id`} element={<MyInfo />} />
                  <Route path="profile" element={<Profile />} />
                  <Route path="notifications" element={<Notifications />} />
                  <Route path="Change-Password" element={<ChangePassword />} />
                </Route>
              </Routes>
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
