import { useState } from "react";
import { RiDeleteBin5Line } from "react-icons/ri";
// import deleteIcon from "../../svg/delete_4041994.svg";
import { Tooltip } from "react-tooltip";
const Delete = ({ onClick, className, name, id }) => {
  const [sure, setSure] = useState(false);
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-60 z-30"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-30 flex justify-center items-center`}
          >
            <div className="bg-white z-40 rounded-3xl w-[400px] max-sm:w-[320px] min-h-[200px]">
              <p className="font-semibold text-xl text-center py-5">
                هل أنت متأكد من حذف العنصر
                <span className="text-red-600 mx-1">
                  {name ? `(${name})` : ""}
                </span>{" "}
                ذو <span className="text-red-600 mx-1">الرقم ({id})</span>؟
              </p>
              <div className="flex items-center justify-center m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={onClick}
                  >
                    نعم
                  </div>
                </div>

                <button
                  onClick={() => setSure(false)}
                  className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                >
                  تراجع
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="w-fit">
        {sessionStorage.getItem("role_name") === "register_manager" ||
        sessionStorage.getItem("role_name") ===
          "financial_collection_officer" ? (
          ""
        ) : (
          <div id="my-anchor-element-del">
            <div
              onClick={() => setSure(true)}
              className={`w-10 h-10  text-red-700 rounded-full hover:bg-slate-200 flex cursor-pointer justify-center items-center ${className}`}
            >
              <RiDeleteBin5Line size={30} className="" />
            </div>
            <Tooltip anchorSelect="#my-anchor-element-del" content="حذف" />
          </div>
        )}
      </div>
    </>
  );
};

export default Delete;
