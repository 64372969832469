import { useFilter } from "../../Tools/APIs";

function PaymentFilter() {
  const { searchParams, handleParamsClick, handleParamsDelete } = useFilter();
  return (
    <div>
      <select
        value={searchParams.get("state")}
        onChange={(e) => handleParamsClick("state", e.target.value)}
        className="w-full bg-Third rounded-xl py-3 text-center px-5 text-white outline-none"
      >
        <option value="" onClick={() => handleParamsDelete("state")}>
          الكل
        </option>
        <option value="2">مقبول</option>
        <option value="1">غير مقبول</option>
      </select>
    </div>
  );
}
export default PaymentFilter;
