import { useState } from "react";
import { usePOST } from "../../Tools/APIs";
import { RiDeviceRecoverLine } from "react-icons/ri";
const Restore = ({ id, idd, name }) => {
  const [sure, setSure] = useState(false);
  const { handleSubmit } = usePOST({});
  const handleClick = () => {
    handleSubmit(`admin/users/${id}/balance/logs/${idd}/restore`, "", "", true);
  };
  return (
    <>
      {sure && (
        <>
          <div
            className="fixed  top-0 left-0 w-full h-full bg-black bg-opacity-60 z-50"
            onClick={() => setSure(false)}
          ></div>
          <div
            className={`fixed  top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 popup z-50 flex justify-center items-center`}
          >
            <div className="bg-white z-[60] rounded-3xl w-[520px] max-sm:w-[320px] min-h-[200px]">
              <RiDeviceRecoverLine
                size={70}
                className="mx-auto text-pink-700 mt-3"
              />
              <p className="font-semibold text-xl text-center py-3 ">
                هل تريد استعادة
                <span className="text-red-700"> ({name})</span> من قائمة
                المحذوفين
              </p>
              <div className="flex items-end justify-center m-5">
                <div className="flex gap-9 mx-3">
                  <div
                    className="px-7 py-3 bg-Third my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={handleClick}
                  >
                    نعم
                  </div>
                  <div
                    className="px-7 py-3 bg-Secondary my-2 rounded-xl text-white cursor-pointer hover:bg-opacity-75"
                    onClick={() => setSure(false)}
                  >
                    تراجع
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div>
        <div
          onClick={() => setSure(true)}
          className={`w-10 h-10 rounded-full flex justify-center items-center cursor-pointer hover:bg-slate-200`}
        >
          <RiDeviceRecoverLine size={27} className="text-pink-700" />
        </div>
      </div>
    </>
  );
};

export default Restore;
